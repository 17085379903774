var TenderUI = (function () {
    function TenderUI() {
        this.b64char = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        this.b64decTable = {};
        for (var i = 0; i < this.b64char.length; i++)
            this.b64decTable[this.b64char.charAt(i)] = i;
        var matches = document.body.querySelectorAll('.cache_dataset');
        for (var _i = 0; _i < matches.length; _i++) {
            var item = matches[_i];
            var data = item.innerHTML.trim();
            var data2 = this.decode(TenderUI.rot13(this.decode(data)));
            item.innerHTML = decodeURIComponent(escape(data2));
            item.className = "";
        }
    }
    TenderUI.rot13 = function (s) {
        return s.split('').map(function (_) {
            if (!_.match(/[A-Za-z]/))
                return _;
            var c = Math.floor(_.charCodeAt(0) / 97);
            var k = (_.toLowerCase().charCodeAt(0) - 83) % 26 || 26;
            return String.fromCharCode(k + ((c == 0) ? 64 : 96));
        }).join('');
    };
    TenderUI.prototype.decode = function (_b64) {
        var tmp = this.decoder(_b64);
        return this.packChar(tmp);
    };
    ;
    TenderUI.prototype.decoder = function (_b64) {
        _b64 = _b64.replace(/[^A-Za-z0-9\+\/]/g, "");
        var md = _b64.length % 4;
        var j, i, tmp;
        var dat = [];
        if (md)
            for (i = 0; i < 4 - md; i++)
                _b64 += "A";
        for (j = i = 0; i < _b64.length; i += 4, j += 3) {
            tmp = (this.b64decTable[_b64.charAt(i)] << 18)
                | (this.b64decTable[_b64.charAt(i + 1)] << 12)
                | (this.b64decTable[_b64.charAt(i + 2)] << 6)
                | this.b64decTable[_b64.charAt(i + 3)];
            dat[j] = tmp >>> 16;
            dat[j + 1] = (tmp >>> 8) & 0xff;
            dat[j + 2] = tmp & 0xff;
        }
        if (md)
            dat.length -= [0, 0, 2, 1][md];
        return dat;
    };
    TenderUI.prototype.packChar = function (_utf16) {
        var str = "";
        for (var i in _utf16)
            str += String.fromCharCode(_utf16[i]);
        return str;
    };
    return TenderUI;
}());
var TenderAdminUI = (function () {
    function TenderAdminUI() {
        var id_end_date = document.getElementById("id_end_date");
        if (id_end_date) {
            id_end_date.onchange = function (ev) {
                var element = ev.target;
                var show_files_till = document.getElementById("id_show_files_till");
                show_files_till.value = element.value;
            };
        }
        var show_tender_form = document.querySelector(".show-req-form");
        if (show_tender_form) {
            show_tender_form.onclick = function (ev) {
                show_tender_form.style.display = "none";
                var form = document.querySelector(".req_form");
                form.style.display = "block";
            };
        }
    }
    return TenderAdminUI;
}());
if (document.body.querySelector('.cache_dataset')) {
    new TenderUI();
}
if (document.body.querySelector('.tender_form,.req_form')) {
    new TenderAdminUI();
}
