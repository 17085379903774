// Table Check All
// -----------------------------------

(function() {
    'use strict';

    $(initTableCheckAll);

    function initTableCheckAll() {
        $('table thead input[type="checkbox"]').on('change', function() {
            var $this = $(this),
                index = $this.index() + 1,
                table = $this.parents('table');
            // Make sure to affect only the correct checkbox column
            table.find('tbody > tr > td:nth-child(' + index + ') input[type="checkbox"]').prop('checked', this.checked);
        });

    }

})();
