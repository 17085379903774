"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var components_autocomplete_1 = require("./components_autocomplete");
var components_utils_2 = require("./components_utils");
var Handlebars = require("handlebars");
var ContactView = (function () {
    function ContactView() {
        this.bind_buttons();
    }
    ContactView.prototype.bind_buttons = function () {
        var _this = this;
        var boss_btns = document.querySelectorAll(".btn-contacts-boss");
        var up_btns = document.querySelectorAll(".btn-contacts-up");
        var down_btns = document.querySelectorAll(".btn-contacts-down");
        var publish_contacts_btns = document.querySelectorAll(".btn-publish-contact");
        var publish_deps_btns = document.querySelectorAll(".btn-publish-department");
        var contact_modal = document.querySelectorAll(".contact-modal");
        for (var _i = 0; _i < boss_btns.length; _i++) {
            var btn = boss_btns[_i];
            btn.onchange = function (ev) {
                _this.btn_action(ev, "boss");
            };
        }
        for (var _i = 0; _i < up_btns.length; _i++) {
            var btn = up_btns[_i];
            btn.onclick = function (ev) {
                _this.btn_action(ev, "up");
            };
        }
        for (var _i = 0; _i < down_btns.length; _i++) {
            var btn = down_btns[_i];
            btn.onclick = function (ev) {
                _this.btn_action(ev, "down");
            };
        }
        for (var _i = 0; _i < publish_contacts_btns.length; _i++) {
            var btn = publish_contacts_btns[_i];
            btn.onchange = function (ev) {
                _this.btn_action(ev, "publish_cont");
            };
        }
        for (var _i = 0; _i < publish_deps_btns.length; _i++) {
            var btn = publish_deps_btns[_i];
            btn.onchange = function (ev) {
                _this.btn_action(ev, "publish_dep");
            };
        }
        for (var _i = 0; _i < contact_modal.length; _i++) {
            var btn = contact_modal[_i];
            btn.onclick = function (ev) {
                _this.open_modal(ev);
            };
        }
    };
    ContactView.prototype.open_modal = function (event) {
        var button = event.target;
        var url = button.getAttribute("data-url");
        fetch(url, {
            credentials: 'same-origin'
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            var template = Handlebars.compile(document.getElementById("more-info-modal-tmpl").textContent);
            var more_info = document.getElementById("more-info");
            more_info.innerHTML = template(json);
        }).catch(function (ex) {
            alert("Error occurred. Try again later.");
            if (window.console)
                console.log('parsing failed', ex);
        });
    };
    ContactView.prototype.btn_action = function (event, type) {
        event.preventDefault();
        var button = event.target;
        var id = button.getAttribute("data-id");
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', type);
        data.append('pk', button.getAttribute("data-id"));
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response.success != 'true') {
                if (response.message !== undefined) {
                    alert(response.message);
                }
                else {
                    alert(gettext("Error submitting. Try again."));
                }
            }
            else {
                var element = components_utils_2.getClosest(event.target, 'tr');
                if (type == 'up') {
                    element.parentElement.insertBefore(element, element.previousElementSibling);
                }
                else if (type == 'down') {
                    element.parentElement.insertBefore(element, element.nextElementSibling.nextElementSibling);
                }
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed setting in/out', ex);
        });
    };
    ContactView.setup_search = function () {
        var contact_search = document.getElementById("contact_search");
        var div_contact_search = new components_autocomplete_1.AutoComplete({
            EmptyMessage: "No item found",
            _Render: function (response) {
                var ul;
                if (response !== "") {
                    var json_response = JSON.parse(response);
                    ul = this._RenderResponseItems(json_response);
                }
                if (this.DOMResults.hasChildNodes()) {
                    this.DOMResults.removeChild(this.DOMResults.childNodes[0]);
                }
                if (json_response)
                    this.DOMResults.appendChild(ul);
            },
            _RenderResponseItems: function (response) {
                var ul = document.createElement("ul"), li = document.createElement("li");
                for (var item = 0; item < response.length; item++) {
                    li.innerHTML = response[item].text;
                    if (response[item].is_away)
                        li.innerHTML += "<i style='float:right;pointer-events: none;'><small>Prombūtnē</small></i>";
                    li.setAttribute("data-autocomplete-value", response[item].text);
                    li.setAttribute("data-url", response[item].url);
                    ul.appendChild(li);
                    li = document.createElement("li");
                }
                ul.onclick = function (ev) {
                    var button = ev.target;
                    var url = button.getAttribute("data-url");
                    fetch(url, {
                        credentials: 'same-origin'
                    }).then(function (response) {
                        return response.json();
                    }).then(function (json) {
                        var template = Handlebars.compile(document.getElementById("more-info-modal-tmpl").textContent);
                        var more_info = document.getElementById("more-info");
                        more_info.innerHTML = template(json);
                        var l = components_utils_2.bsn_function("Modal", "#more-info");
                        l.show();
                    }).catch(function (ex) {
                        alert("Error occurred. Try again later.");
                        if (window.console)
                            console.log('parsing failed', ex);
                    });
                };
                return ul;
            },
            _Select: function (item) {
                return;
            },
            _Blur: function (now) {
                if (now === void 0) { now = false; }
            }
        }, contact_search);
    };
    return ContactView;
}());
if (document.querySelector(".contact-list")) {
    var contacts_view = new ContactView();
    ContactView.setup_search();
}
