"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var components_utils_2 = require("./components_utils");
var _flatpickr = require("flatpickr");
var flatpickr = _flatpickr;
var lv_1 = require("flatpickr/dist/l10n/lv");
var Choices = require("choices.js");
var app_submission_1 = require("./app_submission");
exports.dateOptions = {
    dateFormat: 'Y-m-d',
    time_24hr: true,
    locale: lv_1.Latvian
};
exports.dateTimeOptions = __assign(__assign({}, exports.dateOptions), { enableTime: true, dateFormat: 'Y-m-d H:i' });
var FormUI = (function () {
    function FormUI() {
        this.datetime();
        this.date();
        this.add_formsets();
        this.disable_enter();
        this.choices();
        this.choices_invalid();
    }
    FormUI.prototype.Item_inline_class_added = function (row) {
        var submission_row = new app_submission_1.Submission(row);
        submission_row.set_form_fields();
    };
    FormUI.prototype.disable_enter = function () {
        window.addEventListener('keydown', function (event) {
            var element = event.target;
            var f = components_utils_2.getClosest(element, "form");
            if (f && !f.classList.contains('enter-submit')) {
                if (element.tagName == 'INPUT') {
                    if (event.keyCode == 13) {
                        event.stopPropagation();
                        event.preventDefault();
                        return false;
                    }
                }
            }
        }, true);
    };
    FormUI.prototype.datetime = function () {
        var dt = document.body.querySelectorAll('.datetimeinput');
        for (var _ii = 0; _ii < dt.length; _ii++) {
            var element = dt[_ii];
            flatpickr(element, __assign({}, exports.dateTimeOptions));
        }
    };
    FormUI.prototype.date = function () {
        var dt = document.body.querySelectorAll('.dateinput');
        for (var _ii = 0; _ii < dt.length; _ii++) {
            var element = dt[_ii];
            flatpickr(element, __assign({}, exports.dateOptions));
        }
    };
    FormUI.prototype.choices = function () {
        var dt = document.body.querySelectorAll('select.choicesinput');
        for (var _ii = 0; _ii < dt.length; _ii++) {
            var element = dt[_ii];
            new Choices(element);
        }
    };
    FormUI.prototype.choices_invalid = function () {
        var dt = document.body.querySelectorAll('select.is-invalid');
        for (var _ii = 0; _ii < dt.length; _ii++) {
            var element = dt[_ii];
            var choices = components_utils_2.getClosest(element, '.choices');
            choices.classList.add('is-invalid');
        }
    };
    FormUI.prototype.call_formset_function = function (row, container, type) {
        var function_name;
        var classes = container.classList;
        for (var _i = 0; _i < classes.length; _i++) {
            if (classes[_i].indexOf('_inline_class') !== -1) {
                function_name = classes[_i];
            }
        }
        function_name += '_' + type;
        if (window.console)
            console.log(function_name);
        if (typeof this[function_name] === 'function') {
            return this[function_name](row);
        }
    };
    FormUI.prototype.add_formsets = function () {
        var _this = this;
        var formsets = document.querySelectorAll(".django-inline-form");
        for (var _i = 0; _i < formsets.length; _i++) {
            var formset = formsets[_i];
            var prefix = formset.dataset['prefix'];
            var container = formset.querySelector(".formset_container");
            formset.formset({
                container: container,
                prefix: prefix,
                addCssSelector: '.add-row',
                deleteButtonSelector: '.delete-row',
                formTemplateSelector: ".template",
                added: function (row) {
                    _this.call_formset_function(row, formset, 'added');
                },
                removed: function (row) {
                    _this.call_formset_function(row, formset, 'removed');
                }
            });
        }
    };
    return FormUI;
}());
if (document.body.querySelector('form')) {
    new FormUI();
}
var CargoFormController = (function () {
    function CargoFormController(form) {
        this.formElem = form;
        this.newAttachments = {};
        this.attachmentsToDelete = [];
        this.init();
        console.log('cargo form content controller initialized');
    }
    CargoFormController.prototype.init = function () {
        this.withCargoInput = this.formElem.querySelector('.with-cargo-input');
        this.errorIfFalse(this.withCargoInput);
        this.withoutCargoInput = this.formElem.querySelector('.without-cargo-input');
        this.errorIfFalse(this.withoutCargoInput);
        this.containerNumberInput = this.formElem.querySelector('.container_number');
        this.containerNumber2Input = this.formElem.querySelector('.container_number2');
        this.containerIsFull = this.formElem.querySelector('[name="container_is_full"]');
        this.container2IsFull = this.formElem.querySelector('[name="container2_is_full"]');
        this.mrnListContainer = this.formElem.querySelector('.mrn-list');
        this.addMrnBtn = this.formElem.querySelector('.add-mrn-btn');
        this.attachmentFileInput = this.formElem.querySelector('.custom-file-input');
        this.attachmentsListContainer = this.formElem.querySelector('.uploaded-files-list');
        this.setupCargoStateManagementLogic();
        this.setupMrnManagementLogic();
        this.initFormEventHandlers();
        this.initAttachmentsLogic();
    };
    CargoFormController.prototype.errorIfFalse = function (condition, description) {
        if (description === void 0) { description = ''; }
        if (!condition) {
            throw new Error(description);
        }
    };
    CargoFormController.prototype.setupCargoStateManagementLogic = function () {
        var _this = this;
        var _a, _b;
        this.formElem.querySelectorAll('[name=with-cargo]').forEach(function (input) {
            input.addEventListener('change', function () { return _this.handleHasCargoChange(); });
        });
        (_a = this.containerIsFull) === null || _a === void 0 ? void 0 : _a.addEventListener('change', function () {
            _this.handleContainerCargoStateChange();
        });
        (_b = this.container2IsFull) === null || _b === void 0 ? void 0 : _b.addEventListener('change', function () {
            _this.handleContainerCargoStateChange();
        });
        this.handleHasCargoChange();
        this.handleContainerCargoStateChange();
    };
    CargoFormController.prototype.handleHasCargoChange = function () {
        var _a, _b;
        this.showElementsForCargo(!!((_a = this.withCargoInput) === null || _a === void 0 ? void 0 : _a.checked));
        if ((_b = this.withCargoInput) === null || _b === void 0 ? void 0 : _b.checked) {
            this.makeSureAtLeastOneContainerWithCargo();
        }
        else {
            if (this.containerIsFull)
                this.containerIsFull.value = "false";
            if (this.container2IsFull)
                this.container2IsFull.value = "false";
            this.updateContainerNumbersRequiredState();
        }
    };
    CargoFormController.prototype.handleContainerCargoStateChange = function () {
        var _a, _b, _c, _d;
        if (((_a = this.containerIsFull) === null || _a === void 0 ? void 0 : _a.value) == "false" && ((_b = this.container2IsFull) === null || _b === void 0 ? void 0 : _b.value) == "false") {
            if (this.withoutCargoInput && ((_c = this.withCargoInput) === null || _c === void 0 ? void 0 : _c.checked)) {
                this.withoutCargoInput.click();
            }
        }
        else if (this.withCargoInput && ((_d = this.withoutCargoInput) === null || _d === void 0 ? void 0 : _d.checked)) {
            this.withCargoInput.click();
        }
        this.updateContainerNumbersRequiredState();
    };
    CargoFormController.prototype.updateContainerNumbersRequiredState = function () {
        var updateInputRequiredState = function (input, state) {
            var _a;
            if (input) {
                if (((_a = state) === null || _a === void 0 ? void 0 : _a.value) === "true") {
                    input.setAttribute("required", "");
                }
                else {
                    input.removeAttribute("required");
                }
            }
        };
        updateInputRequiredState(this.containerNumberInput, this.containerIsFull);
        updateInputRequiredState(this.containerNumber2Input, this.container2IsFull);
    };
    CargoFormController.prototype.showElementsForCargo = function (show) {
        this.formElem.querySelectorAll('.show-with-cargo').forEach(function (elem) {
            elem.classList.toggle('d-none', !show);
        });
    };
    CargoFormController.prototype.makeSureAtLeastOneContainerWithCargo = function () {
        var _a, _b;
        if (((_a = this.containerIsFull) === null || _a === void 0 ? void 0 : _a.value) == "false" && ((_b = this.container2IsFull) === null || _b === void 0 ? void 0 : _b.value) == "false") {
            this.containerIsFull.value = "true";
        }
        this.updateContainerNumbersRequiredState();
    };
    CargoFormController.prototype.setupMrnManagementLogic = function () {
        var _this = this;
        var _a;
        if (!this.addMrnBtn)
            return;
        (_a = this.mrnListContainer) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function (e) {
            var _a;
            var targetHtmlElem = e.target;
            if (targetHtmlElem.classList.contains("delete-mrn-btn")) {
                e.preventDefault();
                (_a = targetHtmlElem.closest('.mrn-item')) === null || _a === void 0 ? void 0 : _a.remove();
                _this.updateStateOfMrnDeletionButtons();
                _this.setupMrnInputsManagementLogic();
            }
        });
        this.addMrnBtn.addEventListener('click', function (e) {
            var _a;
            e.preventDefault();
            var mrnItemNode = _this.findHtmlTemplate('.mrn-item');
            (_a = _this.mrnListContainer) === null || _a === void 0 ? void 0 : _a.append(mrnItemNode.cloneNode(true));
            _this.updateStateOfMrnDeletionButtons();
            _this.setupMrnInputsManagementLogic();
        });
        this.updateStateOfMrnDeletionButtons();
        this.setupMrnInputsManagementLogic();
    };
    CargoFormController.prototype.findHtmlTemplate = function (selector) {
        selector = '.templates >' + selector;
        var tempalteNode = document.querySelector(selector);
        if (!tempalteNode) {
            console.error('Unable to find template ', selector);
            throw new Error("Unable to find template by selector: " + selector);
        }
        return tempalteNode;
    };
    CargoFormController.prototype.setupMrnInputsManagementLogic = function () {
        var _a, _b;
        var mrnItemsList = (_a = this.mrnListContainer) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.mrn-item');
        (_b = mrnItemsList) === null || _b === void 0 ? void 0 : _b.forEach(function (itemElement) {
            var _a;
            var htmlItemElement = itemElement;
            var cunstomStatusSelector = htmlItemElement.querySelector(".custom-status-selector");
            var mrnNumberInput = htmlItemElement.querySelector(".mrn-number");
            if (mrnItemsList.length > 1) {
                cunstomStatusSelector.setAttribute('required', '');
            }
            else {
                cunstomStatusSelector.removeAttribute("required");
            }
            if (htmlItemElement.getAttribute("initialized") == "true") {
                return;
            }
            htmlItemElement.setAttribute("initialized", "true");
            var changeCustomStatusListener = function () {
                if (cunstomStatusSelector.value !== "") {
                    mrnNumberInput.setAttribute("required", "");
                }
                else {
                    mrnNumberInput.removeAttribute("required");
                }
            };
            (_a = cunstomStatusSelector) === null || _a === void 0 ? void 0 : _a.addEventListener("change", function () {
                changeCustomStatusListener();
            });
            changeCustomStatusListener();
        });
    };
    CargoFormController.prototype.updateStateOfMrnDeletionButtons = function () {
        var _a;
        if (this.mrnListContainer) {
            var blockDeletion_1 = this.mrnListContainer.querySelectorAll('.mrn-item').length == 1;
            (_a = this.mrnListContainer) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.mrn-item').forEach(function (elem) {
                var _a;
                var htmlMrnItem = elem;
                (_a = htmlMrnItem.querySelector('.delete-mrn-btn')) === null || _a === void 0 ? void 0 : _a.classList.toggle('d-none', blockDeletion_1);
            });
        }
    };
    CargoFormController.prototype.initFormEventHandlers = function () {
        var _this = this;
        this.formElem.addEventListener('submit', function (e) {
            var _a;
            e.preventDefault();
            var formData = new FormData(e.target);
            if ((_a = _this.formElem.querySelector('.mrn-block')) === null || _a === void 0 ? void 0 : _a.classList.contains('d-none')) {
                formData.delete('mrn_number');
                formData.delete('customs_status');
            }
            for (var i = 0; i < _this.attachmentsToDelete.length; i++) {
                formData.append("delete-attachment-id", _this.attachmentsToDelete[i]);
            }
            for (var _b = 0, _c = Object.entries(_this.newAttachments); _b < _c.length; _b++) {
                var _d = _c[_b], id = _d[0], file = _d[1];
                console.log(file);
                formData.append("attachment", file);
            }
            fetch(_this.formElem.getAttribute('action'), {
                body: formData,
                method: "post"
            }).then(function (response) {
                console.log(response);
                if (response.redirected) {
                    window.location.href = response.url;
                }
            })
                .catch(function (err) {
                console.info(err);
            });
        });
    };
    CargoFormController.prototype.initAttachmentsLogic = function () {
        var _this = this;
        var _a;
        if (!this.attachmentFileInput || !this.attachmentsListContainer)
            return;
        (_a = this.attachmentsListContainer) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function (e) {
            var _a, _b, _c, _d, _e, _f;
            var targetHtmlElem = e.target;
            if (targetHtmlElem.classList.contains("delete-attachment-btn")) {
                e.preventDefault();
                var closestAttachmentItem = targetHtmlElem.closest('.attachment-item');
                if ((_a = closestAttachmentItem) === null || _a === void 0 ? void 0 : _a.dataset.id) {
                    if (closestAttachmentItem.classList.contains('existing')) {
                        console.log("deleting existing attachment", (_b = closestAttachmentItem) === null || _b === void 0 ? void 0 : _b.dataset.id);
                        _this.attachmentsToDelete.push((_c = closestAttachmentItem) === null || _c === void 0 ? void 0 : _c.dataset.id);
                    }
                    else {
                        console.log('deleting new attachment', (_d = closestAttachmentItem) === null || _d === void 0 ? void 0 : _d.dataset.id);
                        delete _this.newAttachments[(_e = closestAttachmentItem) === null || _e === void 0 ? void 0 : _e.dataset.id];
                    }
                }
                (_f = closestAttachmentItem) === null || _f === void 0 ? void 0 : _f.remove();
            }
        });
        this.attachmentFileInput.addEventListener('change', function (e) {
            var _a, _b, _c;
            for (var i = 0; i < ((_a = e.currentTarget.files) === null || _a === void 0 ? void 0 : _a.length); i++) {
                var file = (_c = (_b = e) === null || _b === void 0 ? void 0 : _b.currentTarget) === null || _c === void 0 ? void 0 : _c.files[i];
                if (file) {
                    _this.enqueueFileToUpload(file);
                }
            }
        });
    };
    CargoFormController.prototype.enqueueFileToUpload = function (file) {
        var _a;
        var newFileId = this.geenrateRandomId();
        this.newAttachments[newFileId] = file;
        var templateNode = this.findHtmlTemplate('.attachment-item').cloneNode(true);
        templateNode.setAttribute("data-id", newFileId);
        var fileNameElem = templateNode.querySelector(".file-name");
        if (fileNameElem) {
            fileNameElem.textContent = file.name;
        }
        (_a = this.attachmentsListContainer) === null || _a === void 0 ? void 0 : _a.append(templateNode);
    };
    CargoFormController.prototype.geenrateRandomId = function () {
        var s4 = function () {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    return CargoFormController;
}());
var cargoFormContainers = document.querySelectorAll('.cargo-form');
if (cargoFormContainers.length) {
    cargoFormContainers.forEach(function (form) {
        new CargoFormController(form);
    });
}
