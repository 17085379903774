"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var components_utils_2 = require("./components_utils");
var Cookies = require("js-cookie");
var reconnecting_websocket_1 = require("reconnecting-websocket");
var device_uuid_1 = require("device-uuid");
var alertify = require("alertifyjs/build/alertify.js");
function setup_notifications() {
    if (!Notification) {
        console.log('Desktop notifications are not available in your browser.');
        return;
    }
    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
    }
}
function showNotification(payload) {
    console.log(payload);
    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
    }
    else {
        console.log("send");
        var options = {
            body: payload.message,
            dir: 'ltr',
            requireInteraction: true,
        };
        var notification = new Notification('Notification', options);
        console.log(notification);
        alertify.confirm(payload.message, function () { window.location.href = payload.url; }).set({ title: "Paziņojums" }).set('labels', { ok: 'Atvērt', cancel: 'Atcelt' });
        ;
        notification.onclick = function () {
            window.open(payload.url);
        };
        if (payload.total_notifications) {
            document.querySelector("#notification-open .badge").innerHTML = payload.total_notifications;
            document.querySelector("#notification-open .badge").classList.remove("d-none");
        }
    }
}
if (typeof notification_ws !== 'undefined') {
    setup_notifications();
    var uuid = new device_uuid_1.DeviceUUID().get();
    var socket = new reconnecting_websocket_1.default(notification_ws + "?token=" + uuid, null, { reconnectInterval: 3000 });
    socket.onmessage = function (evt) {
        console.log(evt);
        if (evt.data == "ping") {
            socket.send("pong");
        }
        else {
            var msg = evt.data;
            var obj = JSON.parse(msg);
            console.log(obj);
            if (obj.type == "notification") {
                showNotification(obj);
            }
        }
    };
}
if (document.querySelector("#login-form .timeleft")) {
    var countdownNum_1 = 40;
    var incTimer_1 = function () {
        setTimeout(function () {
            if (countdownNum_1 != 0) {
                countdownNum_1--;
                document.querySelector('#login-form .timeleft').innerHTML = countdownNum_1.toString();
                incTimer_1();
            }
            else {
                document.querySelector('#login-form .resend-btn').disabled = null;
                document.querySelector('#login-form .timeleft').innerHTML = '';
            }
        }, 1000);
    };
    incTimer_1();
}
if (document.querySelector("#login-form")) {
    var login_form_1 = document.querySelector("#login-form");
    login_form_1.onsubmit = function (ev) {
        var buttons = login_form_1.querySelectorAll('input[type=submit]');
        for (var i = 0; i < buttons.length; i++) {
            var button_1 = buttons[i];
            button_1.disabled = true;
        }
    };
}
var epermit = document.querySelectorAll('.email_sms');
for (var i = 0; i < epermit.length; i++) {
    epermit[i].addEventListener('click', function (event) {
        var email_sms_arr = this.id.split('-');
        var item = email_sms_arr[0];
        var modal_type = email_sms_arr[1];
        document.getElementById("item_id").value = item;
        document.getElementById("email-label").style.display = 'none';
        document.getElementById("sms-label").style.display = 'none';
        if (modal_type === 'email') {
            document.getElementById("email-label").style.display = 'block';
            document.getElementById("email-sms-text").setAttribute('name', 'email');
            document.getElementById("email-sms-text").setAttribute('type', 'email');
        }
        else {
            document.getElementById("sms-label").style.display = 'block';
            document.getElementById("email-sms-text").setAttribute('name', 'sms');
            document.getElementById("email-sms-text").setAttribute('type', 'text');
        }
    });
}
if (document.getElementById("delete_gallery_modal")) {
    var del_btns = document.querySelectorAll(".delete-album");
    var delete_modal_1 = document.querySelector("#delete_gallery_modal");
    if (del_btns.length) {
        for (var i_1 = 0; i_1 < del_btns.length; i_1++) {
            del_btns[i_1].onclick = function () {
                var input_field = delete_modal_1.querySelector("#album_id");
                input_field.value = event.target.dataset["album_id"];
                var modal_txt = delete_modal_1.querySelector("#body-text");
                modal_txt.innerText = event.target.dataset["album_title"];
                delete_modal_1.modal.open();
            };
        }
    }
}
if (document.querySelector('.archive_documents, #id_news_post_form')) {
    if (document.querySelector(".openpopup")) {
        var buttons = document.querySelectorAll(".openpopup");
        buttons.forEach(function (button) {
            button.onclick = function (ev) {
                var element = ev.target;
                var myWindow = window.open("" + element.dataset['url'], "Related Document", "width=1600, height=1000");
                myWindow.focus();
                var myWindowTick = setInterval(function () {
                    if (myWindow.closed) {
                        clearInterval(myWindowTick);
                        delete_entries();
                    }
                }, 500);
            };
        });
    }
    if (document.getElementById('del_btn')) {
        document.getElementById('del_btn').onclick = remove_img;
    }
    delete_entries();
}
function delete_entries() {
    if (document.querySelector(".delete-entry")) {
        var del_buttons = document.querySelectorAll(".delete-entry");
        var _loop_1 = function (i_2) {
            var button_2 = del_buttons[i_2];
            button_2.onclick = function (ev) {
                var sel = document.querySelector("#id_docs > option[value='" + button_2.getAttribute("data-record-id") + "']");
                if (!sel) {
                    sel = document.querySelector("#id_galleries > option[value='" + button_2.getAttribute("data-record-id") + "']");
                }
                if (sel) {
                    sel.remove();
                    ev.target.parentElement.parentElement.remove();
                }
            };
        };
        for (var i_2 = 0; i_2 < del_buttons.length; i_2++) {
            _loop_1(i_2);
        }
    }
}
function remove_img(self) {
    self.target.classList.add('hidden');
    document.getElementById('main_img').classList.add('hidden');
    document.getElementById('id_main_image_input').value = '';
    document.getElementById('img_message').classList.remove('hidden');
}
if (document.querySelector(".dismiss-card")) {
    var dismiss_btns = document.querySelectorAll('.dismiss-card');
    dismiss_btns.forEach(function (btn) {
        btn.onclick = function (ev) {
            var card = components_utils_2.getClosest(ev.target, '.card');
            Cookies.set('card_dismiss-' + card.id, 'hidden', { expires: 7 });
            card.classList.add('d-none');
        };
        var card = components_utils_2.getClosest(btn, '.card');
        if (Cookies.get('card_dismiss-' + card.id) === undefined)
            card.classList.remove('d-none');
    });
}
if (document.querySelector(".delete-button-alertify")) {
    var button_list = document.body.querySelectorAll('.delete-button-alertify');
    for (var _i = 0; _i < button_list.length; _i++) {
        var button = button_list[_i];
        button.onclick = function (ev) {
            var clicked_button = ev.target;
            var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
            alertify.confirm(gettext("Are you sure?"), function () {
                var data = new FormData();
                data.append('csrfmiddlewaretoken', csrf.value);
                data.append("action", "delete");
                data.append("item_id", clicked_button.getAttribute("data-id"));
                var url = clicked_button.getAttribute("data-url");
                if (url === undefined || url == null) {
                    url = window.location.href;
                }
                fetch(url, {
                    credentials: 'same-origin',
                    method: 'POST',
                    body: data
                }).then(function (response) {
                    var closest_tr = components_utils_2.getClosest(clicked_button, "tr");
                    if (clicked_button.dataset['redirect'])
                        window.location.href = clicked_button.dataset['redirect'];
                    if (closest_tr)
                        closest_tr.remove();
                }).catch(function (ex) {
                    alertify.error("Error occurred. Try again later.");
                    if (window.console)
                        console.log('failed deleting', ex);
                });
            }, function () {
            });
        };
    }
}
function isIE() {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
}
var showBrowserAlert = (function () {
    if (document.querySelector('.unsupported-browser')) {
        var d = document.getElementsByClassName('unsupported-browser');
        if (isIE()) {
            d[0].style.display = 'block';
        }
    }
});
document.addEventListener('DOMContentLoaded', showBrowserAlert);
