"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var components_utils_2 = require("./components_utils");
var Handlebars = require("handlebars");
var moment = require("moment");
Handlebars.registerHelper("formatDate", function (datetime) {
    return moment(datetime).format("YYYY-MM-DD HH:mm");
});
function railway_modal(button_list) {
    for (var _i = 0; _i < button_list.length; _i++) {
        var button = button_list[_i];
        button.onclick = function (ev) {
            var target = ev.target;
            if (target.tagName != 'A') {
                return;
            }
            ev.preventDefault();
            var button = ev.target;
            if (!button.classList.contains("wagon-modal")) {
                button = components_utils_2.getClosest(button, ".wagon-modal");
            }
            document.getElementById("more-info").innerHTML = "";
            fetch(button.dataset['url'], {
                credentials: 'same-origin'
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                var template = Handlebars.compile(document.getElementById("more-info-modal-tmpl").textContent);
                var more_info = document.getElementById("more-info");
                more_info.innerHTML = template(json);
            }).catch(function (ex) {
                alert("Error occurred. Try again later.");
                if (window.console)
                    console.log('parsing failed', ex);
            });
        };
    }
}
if (document.querySelector(".wagon-list")) {
    var button_list = document.body.querySelectorAll('.wagon-modal');
    railway_modal(button_list);
}
if (document.getElementById('asyncXlsxExportModal')) {
    var exportAnchor = document.getElementById("dropdownMenuLink").parentElement.querySelector("a.dropdown-item[href*='action=export_xls']");
    var requestURL_1 = exportAnchor.href;
    exportAnchor.href = "#";
    var loadingModal_1 = components_utils_2.bsn_function("Modal", "#asyncXlsxExportModal");
    exportAnchor.onclick = function (ev) {
        loadingModal_1.show();
        fetch(requestURL_1, { credentials: 'same-origin' })
            .then(function (response) { return response.json(); })
            .then(function (json) {
            if (json.status) {
                var task_id_1 = json.task_id;
                if (json.status == 'download') {
                    loadingModal_1.hide();
                    window.location = json.location + "?action=download_file&task_id=" + task_id_1;
                }
                else {
                    var interval_id_1 = setInterval(function () {
                        fetch(json.location + '?action=check_result&task_id=' + task_id_1, { credentials: 'same-origin' })
                            .then(function (resp2) { return resp2.json(); })
                            .then(function (json2) {
                            if (json2.status) {
                                if (json2.status == 'download') {
                                    loadingModal_1.hide();
                                    clearInterval(interval_id_1);
                                    window.location = json2.location + "?action=download_file&task_id=" + task_id_1;
                                }
                                else if (json2.status == 'failed') {
                                    clearInterval(interval_id_1);
                                    alert('failed :(');
                                    loadingModal_1.hide();
                                }
                            }
                        });
                    }, 1000);
                }
            }
        })
            .catch(function (ex) {
            alert("Error occurred. Try again later.");
            if (window.console)
                console.log('parsing failed', ex);
        });
    };
}
