"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getClosest(elem, selector) {
    var firstChar = selector.charAt(0);
    var supports = 'classList' in document.documentElement;
    var attribute, value;
    if (firstChar === '[') {
        selector = selector.substr(1, selector.length - 2);
        attribute = selector.split('=');
        if (attribute.length > 1) {
            value = true;
            attribute[1] = attribute[1].replace(/"/g, '').replace(/'/g, '');
        }
    }
    for (; elem && elem !== document.body && elem.nodeType === 1; elem = elem.parentNode) {
        if (firstChar === '.') {
            if (supports) {
                if (elem.classList.contains(selector.substr(1))) {
                    return elem;
                }
            }
            else {
                if (new RegExp('(^|\\s)' + selector.substr(1) + '(\\s|$)').test(elem.className)) {
                    return elem;
                }
            }
        }
        if (firstChar === '#') {
            if (elem.id === selector.substr(1)) {
                return elem;
            }
        }
        if (firstChar === '[') {
            if (elem.hasAttribute(attribute[0])) {
                if (value) {
                    if (elem.getAttribute(attribute[0]) === attribute[1]) {
                        return elem;
                    }
                }
                else {
                    return elem;
                }
            }
        }
        if (elem.tagName.toLowerCase() === selector) {
            return elem;
        }
    }
    return null;
}
exports.getClosest = getClosest;
exports.isIE = (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null) ? parseFloat(RegExp.$1) : false;
function addClass(el, c) {
    if (el.classList) {
        el.classList.add(c);
    }
    else {
        el.className += ' ' + c;
        el.offsetWidth;
    }
}
exports.addClass = addClass;
function removeClass(el, c) {
    if (el.classList) {
        el.classList.remove(c);
    }
    else {
        el.className = el.className.replace(c, '').replace(/^\s+|\s+$/g, '');
    }
}
exports.removeClass = removeClass;
function isElementInViewport(t) {
    var r = t.getBoundingClientRect();
    return (r.top >= 0 &&
        r.left >= 0 &&
        r.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        r.right <= (window.innerWidth || document.documentElement.clientWidth));
}
exports.isElementInViewport = isElementInViewport;
function getScroll() {
    return {
        y: window.pageYOffset || document.documentElement.scrollTop,
        x: window.pageXOffset || document.documentElement.scrollLeft
    };
}
exports.getScroll = getScroll;
exports.mouseHover = ('onmouseleave' in document) ? ['mouseenter', 'mouseleave'] : ['mouseover', 'mouseout'];
exports.tipPositions = /\b(top|bottom|left|top)+/;
exports.flatpickr_lv_i10n = {
    firstDayOfWeek: 1,
    weekdays: {
        shorthand: ['Sv', 'P', 'O', 'T', 'C', 'Pk', 'S'],
        longhand: ['Svētdiena', 'Pirmdiena', 'Otrdiena', 'Trešdiena', 'Ceturtdiena', 'Piektdiena', 'Sestdiena']
    },
    months: {
        shorthand: ['Jan', 'Fab', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        longhand: ['Janvāris', 'Februāris', 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris']
    }
};
exports.bsn_function = function (name, data) {
    for (var _i = 0, _a = BSN.supports; _i < _a.length; _i++) {
        var b = _a[_i];
        if (b[0] == name)
            return new b[1](data);
    }
};
