"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var FormData = require("form-data");
var Handlebars = require("handlebars/dist/handlebars.js");
var VMasker = require("vanilla-masker/build/vanilla-masker.min.js");
var moment = require("moment");
var components_utils_2 = require("./components_utils");
var components_autocomplete_1 = require("./components_autocomplete");
var reconnecting_websocket_1 = require("reconnecting-websocket");
var Choices = require("choices.js");
var _flatpickr = require("flatpickr");
var json_fetch_1 = require("json-fetch");
var qr_scanner_1 = require("qr-scanner");
var flatpickr = _flatpickr;
var alertify = require("alertifyjs/build/alertify.js");
alertify.set('notifier', 'position', 'top-right');
Handlebars.registerHelper("formatDate", function (datetime) {
    return moment(datetime).format("YYYY-MM-DD HH:mm");
});
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2)
        return parts.pop().split(";").shift();
}
function validateSSN(value) {
    var checksum = 1;
    value = value.replace("-", "").trim();
    if (!value || value.length !== 11)
        return false;
    for (var i = 0; i <= 9; i += 1) {
        checksum = checksum - (parseInt(value[i], 10) * parseInt("01060307091005080402".substr(i * 2, 2), 10));
    }
    return checksum - (Math.floor(checksum / 11) * 11) === parseInt(value[10], 10);
}
function maskedSSN(field, is_resident_field) {
    var is_resident = is_resident_field.value == 'True';
    if (is_resident) {
        new VMasker(field).maskPattern("999999-99999");
        field.onchange = function (ev) {
            var input = ev.target;
            var form_group = components_utils_2.getClosest(input, ".form-group");
            if (validateSSN(input.value))
                form_group.classList.remove("has-error");
            else
                form_group.classList.add("has-error");
        };
    }
    else {
        new VMasker(field).unMask();
        field.onchange = null;
        components_utils_2.getClosest(field, '.form-group').classList.remove("has-error");
    }
    return false;
}
function submission_modal(button_list) {
    for (var _i = 0; _i < button_list.length; _i++) {
        var button = button_list[_i];
        button.onclick = function (ev) {
            var target = ev.target;
            if (target.tagName == 'BUTTON') {
                return;
            }
            ev.preventDefault();
            var button = ev.target;
            if (!button.classList.contains("submission-modal")) {
                button = components_utils_2.getClosest(button, ".submission-modal");
            }
            fetch(button.dataset['url'], {
                credentials: 'same-origin'
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                var template = Handlebars.compile(document.getElementById("more-info-modal-tmpl").textContent);
                var more_info = document.getElementById("more-info");
                more_info.innerHTML = template(json);
                TerritoryView.bind_buttons(more_info);
                var l = components_utils_2.bsn_function("Modal", "#more-info");
                l.show();
                var dropdown_el = more_info.querySelector('[data-toggle="dropdown"]');
                if (dropdown_el) {
                    components_utils_2.bsn_function("Dropdown", dropdown_el);
                }
                var tooltip_el = more_info.querySelector('[data-toggle="tooltip"]');
                if (tooltip_el) {
                    components_utils_2.bsn_function("Tooltip", tooltip_el);
                }
                var modal_btns = more_info.querySelectorAll('.contact');
                for (var i_1 = 0; i_1 < modal_btns.length; i_1++) {
                    components_utils_2.bsn_function("Button", modal_btns[i_1]);
                }
            }).catch(function (ex) {
                alert("Error occurred. Try again later.");
                if (window.console)
                    console.log('parsing failed', ex);
            });
        };
    }
}
function submission_picked_item(item) {
    var iframe = document.getElementById("watchtower_iframe");
    try {
        var frameDoc = iframe.contentDocument || iframe.contentWindow.document;
        frameDoc.body.innerHTML = '<div class="spinner-border" role="status" style="margin: 20px auto;">\n' +
            '  <span class="sr-only">Loading...</span>\n' +
            '</div>';
    }
    catch (e) {
    }
    if (!item.temp) {
        if (!item.watch_tower_url || item.have_bct == true)
            iframe.src = item.watch_tower_url_bct;
        else
            iframe.src = item.watch_tower_url;
    }
    var template_side;
    if (item.temp)
        template_side = Handlebars.compile(document.getElementById("permit-side-tmp-tmpl").textContent);
    else if (item.watch_tower_url)
        template_side = Handlebars.compile(document.getElementById("permit-side-tmpl").textContent);
    else
        template_side = Handlebars.compile(document.getElementById("permit-side-bct-tmpl").textContent);
    if (!item.temp) {
        var blocks = document.querySelectorAll(".code" + item.entered_code);
        blocks.forEach(function (block) {
            block.remove();
        });
    }
    var sidebar = document.getElementById("permit-sidebar");
    var ws_cards = sidebar.getElementsByClassName("bg-gray ws_card");
    for (var i_2 = 0; i_2 < ws_cards.length; i_2++) {
        ws_cards[i_2].classList.remove("bg-gray");
    }
    var child = document.createElement('div');
    child.innerHTML = template_side(item);
    child.firstElementChild.classList.add('bg-gray');
    TerritoryView.set_card_actions(child);
    sidebar.prepend(child);
    var sc_perm = sidebar.querySelector(".none-permits-scanned");
    if (sc_perm)
        sc_perm.remove();
    if (sidebar.childElementCount > 15) {
        sidebar.removeChild(sidebar.lastChild);
    }
}
function submission_iframe(li, item) {
    li.childNodes[0].onclick = function (ev) {
        if (ev.target.tagName != 'BUTTON') {
            li.closest(".autocomplete").classList.remove("open");
            submission_picked_item(item);
        }
    };
}
var TerritoryView = (function () {
    function TerritoryView(open_iframe) {
        if (open_iframe === void 0) { open_iframe = false; }
        TerritoryView.bind_buttons(window.document.body);
        TerritoryView.setup_top_filters();
        setTimeout(function () { TerritoryView.setup_territory_search(open_iframe); }, 1000);
        TerritoryView.create_inout_websocket();
    }
    TerritoryView.create_inout_websocket = function () {
        if (typeof inout_ws !== 'undefined') {
            var socket = new reconnecting_websocket_1.default(inout_ws, null, { reconnectInterval: 3000 });
            socket.onmessage = function (evt) {
                var msg = evt.data;
                var obj = JSON.parse(msg);
                var buttons = document.querySelectorAll(".button-" + obj.action + "-" + obj.item_id);
                for (var i = 0; i < buttons.length; i++) {
                    var btn = buttons[i];
                    if (obj.value == "None") {
                        btn.classList.remove("btn-success");
                        btn.classList.add("btn-outline-primary");
                    }
                    else {
                        btn.classList.add("btn-success");
                        btn.classList.remove("btn-outline-primary");
                        btn.classList.remove("btn-warning");
                    }
                }
            };
        }
    };
    TerritoryView.create_websocket = function (endpoint) {
        function set_status(url, connected) {
            var key = Object.keys(guard_devices).find(function (key) { return guard_devices[key] === url; });
            var device_info = document.querySelector("#ws-" + key);
            if (connected)
                device_info.classList.add("connected");
            else
                device_info.classList.remove("connected");
        }
        var socket = new reconnecting_websocket_1.default(endpoint, null, { reconnectInterval: 3000 });
        socket.onerror = function (e) {
            console.log("error", e);
            set_status(e.target.url, false);
        };
        socket.onclose = function (e) {
            console.log("close", e);
            set_status(e.target.url, false);
        };
        socket.onopen = function (e) {
            console.log("open", e);
            set_status(e.target.url, true);
        };
        socket.onmessage = function (evt) {
            var msg = evt.data;
            console.log("message", msg);
            var obj = JSON.parse(msg);
            if (obj.method !== undefined) {
                var sidebar = document.getElementById("permit-sidebar");
                var child = document.createElement('div');
                var blocks = document.querySelectorAll(".code" + obj.code);
                blocks.forEach(function (block) {
                    block.remove();
                });
                child.innerHTML = "<div class='card text-white bg-danger'><div class='card-header'>" + obj.error_msg + "<br />Kods: <a href='" + obj.url + "' target='watchtower_iframe'>" + obj.code + "</a> (" + obj.method + ")<br />Iekārta: " + obj.guard_device + "</div></div>";
                sidebar.prepend(child);
            }
            else {
                submission_picked_item(obj);
            }
        };
    };
    TerritoryView.set_card_actions = function (card) {
        card.onclick = function (ev) {
            var elem = ev.target;
            var current_card = elem.closest(".ws_card");
            if (elem.classList.contains("bct-irop")) {
                return true;
            }
            var picked_cards = document.getElementById("permit-sidebar").getElementsByClassName("card bg-gray");
            for (var j = 0; j < picked_cards.length; j++) {
                picked_cards[j].classList.remove("bg-gray");
            }
            current_card.classList.add("bg-gray");
            var iframe = document.getElementById("watchtower_iframe");
            try {
                var frameDoc = iframe.contentDocument || iframe.contentWindow.document;
                frameDoc.body.innerHTML = '<div class="spinner-border" role="status" style="margin: 20px auto;">\n' +
                    '  <span class="sr-only">Loading...</span>\n' +
                    '</div>';
            }
            catch (e) {
            }
            if (current_card.dataset.url)
                iframe.src = current_card.dataset.url;
            else if (current_card.dataset.urlBct)
                iframe.src = current_card.dataset.urlBct;
        };
    };
    TerritoryView.setup_top_filters = function () {
        var top_form = document.querySelector(".submission-territory-form");
        var territory_dd = top_form.querySelector("#id_territory");
        var time_dd = top_form.querySelector("#id_time");
        var devices_dd = document.querySelector("#id_devices");
        if (devices_dd) {
            var devices_choices = new Choices(devices_dd, { searchEnabled: false, searchChoices: false, removeItemButton: true });
            var div_id_devices_enabled_1 = document.querySelector("#div_id_devices_enabled ul");
            devices_dd.onchange = function (ev) {
                var selected = ev.target.querySelectorAll('option:checked');
                var values = Array.from(selected).map(function (el) { return el.value; });
                localStorage.setItem("id_devices", values);
                div_id_devices_enabled_1.innerHTML = "";
                selected.forEach(function (s) {
                    var li = document.createElement("li");
                    li.setAttribute("id", "ws-" + s.value);
                    li.innerHTML = "<span><a href='/lv/pieteikumi/territory/barcode/v2/gd/" + s.value + "/' target='watchtower_iframe'>" + s.innerText + "</a></span><span class='connected'><i class=\"fas fa-check-circle\"></i></span><span class='disconnected'><i class=\"fas fa-times-circle\"></i></span>";
                    div_id_devices_enabled_1.append(li);
                    TerritoryView.create_websocket(guard_devices[parseInt(s.value)]);
                });
            };
            var dev = localStorage.getItem("id_devices");
            if (dev) {
                devices_choices.setChoiceByValue(dev.split(","));
                devices_dd.dispatchEvent(new Event("change"));
                var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
                var data = new FormData();
                data.append('csrfmiddlewaretoken', csrf.value);
                data.append('task', "initial_fetch");
                data.append('devices', dev);
                fetch(window.location, {
                    credentials: 'same-origin',
                    method: 'POST',
                    body: data
                }).then(function (response) {
                    return response.json();
                }).then(function (response) {
                    var template = Handlebars.compile(document.getElementById("permit-side-tmpl").textContent);
                    var sidebar = document.getElementById("permit-sidebar");
                    for (var _a = 0, _b = response.last_scans; _a < _b.length; _a++) {
                        var item = _b[_a];
                        var child = document.createElement('div');
                        child.innerHTML = template(item);
                        TerritoryView.set_card_actions(child);
                        sidebar.append(child);
                    }
                    if (response.last_scans.length > 0) {
                        var sc_perm = sidebar.querySelector(".none-permits-scanned");
                        if (sc_perm)
                            sc_perm.remove();
                    }
                }).catch(function (ex) {
                    alertify.error("Error occurred. Try again later.");
                    if (window.console)
                        console.log('failed setting in/out', ex);
                });
            }
        }
        if (territory_dd) {
            new Choices(territory_dd, { searchEnabled: false, searchChoices: false });
            territory_dd.onchange = function (ev) {
                top_form.submit();
            };
        }
        if (time_dd) {
            new Choices(time_dd, { searchEnabled: false, searchChoices: false });
            time_dd.onchange = function (ev) {
                top_form.submit();
            };
        }
    };
    TerritoryView.setup_territory_search = function (open_iframe) {
        if (open_iframe === void 0) { open_iframe = false; }
        var territory_search = document.getElementById("territory_search");
        if (!territory_search) {
            return;
        }
        var div_territory_search = new components_autocomplete_1.AutoComplete({
            EmptyMessage: "No item found",
            _Render: function (response) {
                var ul;
                if (response !== "") {
                    var json_response = JSON.parse(response);
                    ul = this._RenderResponseItems(json_response);
                }
                else {
                    ul = document.createElement("ul");
                    var li = document.createElement("li");
                    li.innerHTML = "Nav atrasta neviena caurlaide";
                    ul.appendChild(li);
                }
                if (this.DOMResults.hasChildNodes()) {
                    this.DOMResults.removeChild(this.DOMResults.childNodes[0]);
                }
                this.DOMResults.appendChild(ul);
                window.BSN.initCallback(this.DOMResults);
            },
            _RenderResponseItems: function (response) {
                var ul = document.createElement("ul"), li = document.createElement("li");
                for (var item = 0; item < response.length; item++) {
                    var template = Handlebars.compile(document.getElementById("submission-territory-autocomplete-tmpl").innerHTML);
                    li.innerHTML = template(response[item]);
                    li.setAttribute("data-autocomplete-value", response[item].text);
                    ul.appendChild(li);
                    if (!open_iframe) {
                        submission_modal(li.querySelectorAll(".submission-modal"));
                    }
                    else {
                        submission_iframe(li, response[item]);
                    }
                    li = document.createElement("li");
                }
                TerritoryView.bind_buttons(ul);
                return ul;
            },
            _Select: function (item) {
                return;
            },
            _Blur: function (now) {
                if (now === void 0) { now = false; }
            }
        }, territory_search);
        document.onclick = function (ev) {
            if (!components_utils_2.getClosest(ev.target, "#div_territory_search"))
                document.querySelector("#div_territory_search .autocomplete").setAttribute("class", "autocomplete");
        };
    };
    TerritoryView.in_button = function (event, type) {
        event.preventDefault();
        var button = event.target;
        var id = button.getAttribute("data-id");
        var revert = false;
        if (button.classList.contains("btn-success")) {
            if (confirm(gettext("Do you really want to undo?")))
                revert = true;
            else
                return false;
        }
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', type);
        data.append('revert', revert);
        data.append('pk', button.getAttribute("data-id"));
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response.success != 'true') {
                if (response.message !== undefined) {
                    if (response.modal === true) {
                        var modal_html = document.getElementById("permit-out-confirm");
                        var msg = modal_html.querySelector(".message");
                        msg.innerHTML = response.message;
                        msg.style.backgroundColor = response.color || "";
                        var exit_btn = modal_html.querySelector(".allow-exit-btn");
                        exit_btn.setAttribute("data-id", response.data_id);
                        if (response.allow_exit === true)
                            exit_btn.removeAttribute("disabled");
                        else
                            exit_btn.setAttribute("disabled", "disabled");
                        var report_div = document.getElementById("allow-exit-btn-report-data");
                        report_div.classList.add("hidden");
                        report_div.querySelector("textarea").value = "";
                        report_div.querySelector("button").setAttribute("data-id", response.data_id);
                        var l = components_utils_2.bsn_function("Modal", "#permit-out-confirm");
                        l.show();
                    }
                    else
                        alert(response.message);
                }
                else {
                    alert(gettext("Error submitting. Try again."));
                }
            }
            else {
                if (type == 'out2')
                    type = 'out';
                var buttons3 = document.querySelectorAll(".button-" + type + "-" + button.getAttribute("data-id"));
                for (var i = 0; i < buttons3.length; i++) {
                    var button3 = buttons3[i];
                    if (revert) {
                        button3.classList.remove("btn-success");
                        button3.classList.add("btn-outline-primary");
                    }
                    else {
                        button3.classList.add("btn-success");
                        button3.classList.remove("btn-outline-primary");
                        button3.classList.remove("btn-warning");
                    }
                }
                var l = components_utils_2.bsn_function("Modal", "#permit-out-confirm");
                l.hide();
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed setting in/out', ex);
        });
    };
    TerritoryView.block_button = function (event, type) {
        event.preventDefault();
        var button = event.target;
        var id = button.getAttribute("data-id");
        var revert = false;
        var tr = components_utils_2.getClosest(button, "tr");
        if (tr.classList.contains("table-danger")) {
            if (confirm(gettext("Do you really want to undo?")))
                revert = true;
            else
                return false;
        }
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', type);
        data.append('revert', revert);
        data.append('pk', button.getAttribute("data-id"));
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response.success != 'true') {
                alert(gettext("Error blocking. Try again."));
            }
            else {
                if (revert) {
                    tr.classList.remove("table-danger");
                }
                else {
                    tr.classList.add("table-danger");
                }
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed blocking', ex);
        });
    };
    ;
    TerritoryView.resend_button = function (event, type) {
        event.preventDefault();
        var button = event.target;
        var id = button.getAttribute("data-id");
        var modal = document.getElementById(type + '-resend-modal');
        var first_name = modal.querySelector('span#id_' + type + '_first_name');
        var last_name = modal.querySelector('span#id_' + type + '_last_name');
        var code = modal.querySelector('span#id_' + type + '_code');
        var input = modal.querySelector('input#id_' + type + '_input');
        var approve_btn = modal.querySelector('button#button-' + type + '_approve');
        input.value = "";
        first_name.textContent = button.dataset.first_name;
        last_name.textContent = button.dataset.last_name;
        code.textContent = button.dataset.code;
        if (button.dataset.contact) {
            input.value = button.dataset.contact;
        }
        approve_btn.setAttribute("data-id", id);
        var m = components_utils_2.bsn_function("Modal", "#" + type + "-resend-modal");
        m.show();
    };
    ;
    TerritoryView.report_problem = function (event) {
        event.preventDefault();
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', "report_problem");
        var data_div = document.getElementById("allow-exit-btn-report-data");
        var input = data_div.querySelector("textarea");
        var approve_btn = data_div.querySelector("button");
        var pk = approve_btn.dataset.id;
        data.append('pk', pk);
        data.append('issue', input.value);
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            var message = response.message;
            if (response.success != 'true') {
                alertify.error(message);
            }
            else {
                alertify.success(message);
                var l = components_utils_2.bsn_function("Modal", "#permit-out-confirm");
                l.hide();
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed resending', ex);
        });
    };
    TerritoryView.approve_resend = function (event, type) {
        event.preventDefault();
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', type);
        var input = document.getElementById('id_' + type + '_input');
        var approve_btn = document.getElementById('button-' + type + '_approve');
        var pk = approve_btn.dataset.id;
        var contact = input.value;
        data.append('pk', pk);
        data.append('contact', contact);
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            var message = response.message;
            if (response.success != 'true') {
                alertify.error(message);
            }
            else {
                var info = document.getElementById("epermit_info_" + pk);
                if (info) {
                    info.innerText = "Caurlaide nosūtīta uz " + contact;
                }
                alertify.success(message);
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed resending', ex);
        });
    };
    TerritoryView.inform_button = function (event, type) {
        event.preventDefault();
        var button = event.target;
        var id = button.getAttribute("data-id");
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('task', type);
        data.append('pk', id);
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            var message = response.message;
            if (response.success != 'true') {
                alertify.error(message);
            }
            else {
                alertify.success(message);
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed sending email about leaving', ex);
        });
    };
    TerritoryView.bind_buttons = function (scope) {
        if (scope === void 0) { scope = document.body; }
        var buttons_in = scope.querySelectorAll("button.button-in");
        var buttons_create = scope.querySelectorAll("button.button-create");
        var buttons_out = scope.querySelectorAll("button.button-out");
        var buttons_block = scope.querySelectorAll("button.button-block");
        var buttons_resend_sms = scope.querySelectorAll("button.button-resend_sms");
        var buttons_resend_email = scope.querySelectorAll("button.button-resend_email");
        var button_approve_sms_resend = scope.querySelector('button#button-sms_approve');
        var button_approve_email_resend = scope.querySelector("button#button-email_approve");
        var buttons_inform = scope.querySelectorAll("button.button-inform");
        var print_forms = scope.querySelectorAll(".print-means-in");
        var modal_btn = scope.querySelector(".allow-exit-btn");
        var modal_btn_report = scope.querySelector(".allow-exit-btn-report");
        var modal_btn_report_confirm = scope.querySelector(".allow-exit-btn-report-confirm");
        for (var i = 0; i < print_forms.length; i++) {
            var form = print_forms[i];
            form.onsubmit = function (ev) {
                var form = ev.target;
                var row = components_utils_2.getClosest(form, "td");
                var button_in = row.querySelector("button.button-in");
                if (!button_in.classList.contains("btn-success"))
                    button_in.click();
            };
        }
        for (var i = 0; i < buttons_in.length; i++) {
            var button = buttons_in[i];
            button.onclick = function (ev) {
                TerritoryView.in_button(ev, "in");
                ev.stopPropagation();
            };
        }
        for (var i = 0; i < buttons_create.length; i++) {
            var button = buttons_create[i];
            button.onclick = function (ev) {
                TerritoryView.in_button(ev, "create");
                ev.stopPropagation();
            };
        }
        for (var i = 0; i < buttons_out.length; i++) {
            var button = buttons_out[i];
            button.onclick = function (ev) {
                TerritoryView.in_button(ev, "out");
                ev.stopPropagation();
            };
        }
        for (var i = 0; i < buttons_block.length; i++) {
            var button = buttons_block[i];
            button.onclick = function (ev) {
                TerritoryView.block_button(ev, "block");
                ev.stopPropagation();
            };
        }
        if (modal_btn) {
            modal_btn.onclick = function (ev) {
                TerritoryView.in_button(ev, "out2");
                ev.stopPropagation();
            };
        }
        if (modal_btn_report) {
            modal_btn_report.onclick = function (ev) {
                var inp = document.getElementById("allow-exit-btn-report-data");
                inp.classList.remove('hidden');
            };
        }
        if (modal_btn_report_confirm) {
            modal_btn_report_confirm.onclick = function (ev) {
                TerritoryView.report_problem(ev);
            };
        }
        for (var i = 0; i < buttons_resend_sms.length; i++) {
            var button = buttons_resend_sms[i];
            button.onclick = function (ev) {
                TerritoryView.resend_button(ev, "sms");
                ev.stopPropagation();
            };
        }
        for (var i = 0; i < buttons_resend_email.length; i++) {
            var button = buttons_resend_email[i];
            button.onclick = function (ev) {
                TerritoryView.resend_button(ev, "email");
                ev.stopPropagation();
            };
        }
        if (button_approve_sms_resend) {
            button_approve_sms_resend.onclick = function (ev) {
                TerritoryView.approve_resend(ev, "sms");
                ev.stopPropagation();
            };
        }
        if (button_approve_email_resend) {
            button_approve_email_resend.onclick = function (ev) {
                TerritoryView.approve_resend(ev, "email");
                ev.stopPropagation();
            };
        }
        for (var i = 0; i < buttons_inform.length; i++) {
            var button = buttons_inform[i];
            button.onclick = function (ev) {
                TerritoryView.inform_button(ev, "inform");
                ev.stopPropagation();
            };
        }
    };
    return TerritoryView;
}());
var SubmissionUploader = (function () {
    function SubmissionUploader(row) {
        this.row = row;
        this.div_upload = row.querySelector(".submission_upload");
        this.upload_input = this.div_upload.querySelector(".upload");
        this.upload_text = this.div_upload.querySelector("input[name$=-photo]");
        this.setup();
    }
    SubmissionUploader.prototype.setup = function () {
        var _this = this;
        this.upload_input.onchange = function (ev) {
            _this.start_upload(ev);
        };
    };
    SubmissionUploader.prototype.start_upload = function (ev) {
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData;
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('photo_upload', ev.target.files[0]);
        data.append('photo_input', this.upload_text.getAttribute("name"));
        var oXHR = new XMLHttpRequest();
        oXHR.upload.addEventListener('progress', SubmissionUploader.upload_progress, false);
        oXHR.addEventListener('load', SubmissionUploader.upload_finish, false);
        oXHR.addEventListener('error', SubmissionUploader.upload_error, false);
        oXHR.addEventListener('abort', SubmissionUploader.upload_abort, false);
        oXHR.open('POST', this.upload_input.getAttribute("data-url"));
        oXHR.send(data);
    };
    SubmissionUploader.upload_progress = function (e) {
        if (window.console)
            console.log(e);
    };
    SubmissionUploader.upload_finish = function (e) {
        var target = e.target;
        if (target.status === 503) {
            alert(gettext("System Error. Try again later."));
        }
        var response = JSON.parse(target.response);
        if (response.success === false) {
            alertify.error(response.error);
        }
        else {
            var upload_text = document.querySelector("input[name='" + response.photo_input + "']");
            var upload_image = document.getElementById("id_" + response.photo_input + "_photo");
            upload_text.value = response.filename;
            upload_image.setAttribute("src", response.filename);
        }
    };
    SubmissionUploader.upload_error = function (e) {
        if (window.console)
            console.log(e);
        alert("error");
    };
    SubmissionUploader.upload_abort = function (e) {
        if (window.console)
            console.log(e);
        alert("abort");
    };
    return SubmissionUploader;
}());
var Submission = (function () {
    function Submission(row) {
        this.row = row;
        this.is_resident = row.querySelector("select[name$=-is_resident]");
        this.company = row.querySelector("select[name$=-company]");
        this.person_ipz = row.querySelector("select[name$=-person_ipz]");
        this.auto_ipz = row.querySelector("select[name$=-auto_ipz]");
        this.territory = row.querySelector("select[name$=-territory]");
        this.send_to_territory = row.querySelector("select[name$=-send_to_territory]");
        this.territory_company = row.querySelector("select[name$=-territory_company]");
        this.ssn = row.querySelector("input[name$=-ssn]");
        this.last_name = row.querySelector("input[name$=-last_name]");
        this.first_name = row.querySelector("input[name$=-first_name]");
        this.reg_nr = row.querySelector("input[name$=-reg_nr]");
        this.marka = row.querySelector("select[name$=-marka]");
        this.trailer = row.querySelector("input[name$=-trailer]");
        this.container_number = row.querySelector("input[name$=-container_number]");
        this.container_number2 = row.querySelector("input[name$=-container_number2]");
        this.row_id = row.querySelector("input[name$=-id]");
        this.is_e_permit = row.querySelector("input[name$=-is_e_permit]");
        this.phone = row.querySelector("input[name$=-phone]");
        this.email = row.querySelector("input[name$=-email]");
        this.cargo_kind = row.querySelector("select[name$=-cargo_kind]");
        this.out_cargo = row.querySelector("select[name$=-out_cargo]");
        this.in_cargo = row.querySelector("select[name$=-in_cargo]");
        this.direction = row.querySelector("select[name$=-direction]");
        this.description = components_utils_2.getClosest(row.querySelector("textarea[name$=-description]"), '.col');
        this.popup_button = row.querySelector(".openpopup");
    }
    Submission.prototype.autocomplete = function (element) {
        var self = this;
        new components_autocomplete_1.AutoComplete({
            EmptyMessage: "No item found",
            _Render: function (response) {
                var ul;
                if (response !== "") {
                    var json_response = JSON.parse(response);
                    ul = this._RenderResponseItems(json_response);
                }
                if (this.DOMResults.hasChildNodes()) {
                    this.DOMResults.removeChild(this.DOMResults.childNodes[0]);
                }
                if (json_response)
                    this.DOMResults.appendChild(ul);
            },
            _RenderResponseItems: function (response) {
                var ul = document.createElement("ul"), li = document.createElement("li");
                for (var item = 0; item < response.length; item++) {
                    if (element.name.indexOf("last_name") !== -1) {
                        if (!(response[item].fields.first_name && response[item].fields.last_name))
                            continue;
                        li.innerHTML = response[item].fields.first_name + " " + response[item].fields.last_name;
                        li.setAttribute("data-autocomplete-value", response[item].fields.last_name);
                    }
                    else if (element.name.indexOf("reg_nr") !== -1) {
                        if (!response[item].fields.reg_nr)
                            continue;
                        li.innerHTML = response[item].fields.reg_nr;
                        li.setAttribute("data-autocomplete-value", response[item].fields.reg_nr);
                    }
                    for (var field in response[item].fields) {
                        if (window.console)
                            console.log(field + ": " + response[item].fields[field]);
                        li.setAttribute("data-field-" + field, response[item].fields[field] || "");
                    }
                    ul.appendChild(li);
                    li = document.createElement("li");
                }
                return ul;
            },
            _Select: function (item) {
                if (item.hasAttribute("data-autocomplete-value")) {
                    this.Input.value = item.getAttribute("data-autocomplete-value");
                }
                else {
                    this.Input.value = item.innerHTML;
                }
                if (self.first_name)
                    self.first_name.value = item.getAttribute("data-field-first_name");
                if (self.last_name)
                    self.last_name.value = item.getAttribute("data-field-last_name");
                if (self.reg_nr)
                    self.reg_nr.value = item.getAttribute("data-field-reg_nr");
                if (self.marka) {
                    var val = item.getAttribute("data-field-marka");
                    self.marka_field.setChoices([{ "value": val, "label": val }], 'value', 'label', true);
                    self.marka_field.setChoiceByValue([val]);
                }
                if (self.trailer)
                    self.trailer.value = item.getAttribute("data-field-trailer");
                if (self.container_number)
                    self.container_number.value = item.getAttribute("data-field-container_number");
                if (self.container_number2)
                    self.container_number2.value = item.getAttribute("data-field-container_number2");
                if (item.getAttribute("data-field-is_resident") == 'true') {
                    if (self.ssn)
                        self.ssn.value = VMasker.toPattern(item.getAttribute("data-field-ssn"), "999999-99999");
                    if (self.is_resident_field)
                        self.is_resident_field.setChoiceByValue(['True']);
                }
                else {
                    if (self.ssn)
                        self.ssn.value = item.getAttribute("data-field-ssn");
                    if (self.is_resident_field)
                        self.is_resident_field.setChoiceByValue(['False']);
                }
                if (self.person_ipz)
                    self.person_ipz_field.setChoiceByValue([item.getAttribute("data-field-person_ipz")]);
                if (self.territory_field)
                    self.territory_field.setChoiceByValue([item.getAttribute("data-field-territory")]);
                if (self.send_to_territory_field)
                    self.send_to_territory_field.setChoiceByValue([item.getAttribute("data-field-send_to_territory")]);
                if (self.auto_ipz_field) {
                    var value = item.getAttribute("data-field-auto_ipz");
                    self.auto_ipz_field.setChoiceByValue(value);
                }
                if (self.is_e_permit) {
                    if (value == 'true') {
                        self.is_e_permit.checked = true;
                        self.is_e_permit.dispatchEvent(new Event("change"));
                    }
                }
                if (self.email) {
                    self.email.value = item.getAttribute("data-field-email");
                }
                if (self.phone) {
                    self.phone.value = item.getAttribute("data-field-phone");
                }
                this.Input.setAttribute("data-autocomplete-old-value", this.Input.value);
            },
        }, element);
    };
    Submission.prototype.set_from_popup = function (inputData) {
        var data = JSON.parse(inputData);
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                var input = this[key];
                var input_field = this[key + "_field"];
                if (input === null || input === undefined)
                    continue;
                if (input_field !== undefined && !input_field._isSelectMultipleElement) {
                    if (data[key] === false || data[key] === true)
                        data[key] = data[key].toString().charAt(0).toUpperCase() + data[key].toString().slice(1);
                    if (key == "marka") {
                        input_field.setChoices([{ "value": data[key], "label": data[key] }], 'value', 'label', true);
                    }
                    this[key + "_field"].setChoiceByValue(data[key]);
                }
                else if (input_field !== undefined && input_field._isSelectMultipleElement) {
                    this[key + "_field"].removeActiveItems();
                    this[key + "_field"].setChoiceByValue(data[key]);
                }
                else {
                    input.value = data[key];
                }
            }
        }
    };
    Submission.prototype.set_form_fields = function () {
        var _this = this;
        this.row.submission_object = this;
        if (this.popup_button) {
            this.popup_button.onclick = function (ev) {
                var last_name = _this.row.submission_object.last_name.value;
                var myWindow = window.open(_this.popup_button.getAttribute('data-url') + '?row_id=' + _this.row_id.id + '&search=' + last_name, "Related Document", "width=1200, height=1000");
                myWindow.focus();
                return false;
            };
        }
        if (this.is_resident) {
            if (this.is_resident.value == 'True')
                maskedSSN(this.ssn, this.is_resident);
            this.is_resident_field = new Choices(this.is_resident, {
                searchEnabled: false, searchChoices: false
            });
            this.is_resident.onchange = function (ev) {
                maskedSSN(_this.ssn, _this.is_resident);
            };
        }
        if (this.row.querySelector(".visibility-toggle")) {
            var tg_1 = this.row.querySelector(".visibility-toggle");
            var l = components_utils_2.bsn_function("Dropdown", tg_1);
            var visibility_value_1 = this.row.querySelector("select[name$=visibility]").value;
            var btns = tg_1.querySelectorAll(".dropdown-menu a");
            btns.forEach(function (btn) {
                btn.onclick = function (ev) {
                    var element = ev.target;
                    tg_1.querySelector('button').innerHTML = element.getAttribute('data-name');
                    var inputVisibility = _this.row.querySelector("select[name$=visibility]");
                    inputVisibility.value = element.getAttribute('data-value');
                };
                if (btn.getAttribute("data-value") == visibility_value_1) {
                    tg_1.querySelector('button').innerHTML = btn.getAttribute('data-name');
                }
            });
        }
        if (this.row.querySelector(".submission_upload"))
            var upload = new SubmissionUploader(this.row);
        if (this.company) {
            var options = { search: false };
            if (this.company.childElementCount > 10)
                options = { search: true };
            new Choices(this.company, options);
        }
        if (this.territory) {
            this.territory_field = new Choices(this.territory, { searchEnabled: false, searchChoices: false });
        }
        if (this.marka) {
            this.marka_field = new Choices(this.marka, { searchEnabled: true, searchChoices: true, removeItemButton: true, });
            this.marka.addEventListener('search', function (event) {
                var fetchAsyncA = function (e) { return __awaiter(_this, void 0, void 0, function () {
                    var data;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, json_fetch_1.default(this.marka.dataset.autocomplete + "?q=" + encodeURIComponent(event.detail.value))];
                            case 1:
                                data = _a.sent();
                                this.marka_field.setChoices(data.body, this.marka.dataset.value || 'value', this.marka.dataset.label || 'label', true);
                                return [2];
                        }
                    });
                }); };
                fetchAsyncA(event);
            });
        }
        if (this.send_to_territory) {
            this.send_to_territory_field = new Choices(this.send_to_territory, { searchEnabled: false, searchChoices: false });
        }
        if (this.territory_company) {
            this.territory_company_field = new Choices(this.territory_company, { searchEnabled: false, searchChoices: false });
        }
        if (this.person_ipz) {
            this.person_ipz_field = new Choices(this.person_ipz, { searchEnabled: false, searchChoices: false });
        }
        if (this.auto_ipz) {
            this.auto_ipz_field = new Choices(this.auto_ipz, {
                searchEnabled: false,
                maxItemCount: 3
            });
        }
        if (this.last_name && this.last_name.getAttribute("data-autocomplete"))
            this.autocomplete(this.last_name);
        if (this.reg_nr && this.reg_nr.getAttribute("data-autocomplete"))
            this.autocomplete(this.reg_nr);
        var fields = this.row.querySelectorAll('select[name$=_is_full]');
        var choices_is_full = {};
        fields.forEach(function (field) {
            choices_is_full[field.name] = new Choices(field, { searchEnabled: false, searchChoices: false });
        });
        var cargo_onchange_fn = function (ev) {
            var card_body = components_utils_2.getClosest(ev.target, '.card');
            var containers_container = card_body.querySelector('.containers_container');
            var mrn_number_container = card_body.querySelector('.mrn_number_container');
            var description_container = card_body.querySelector('.description_container');
            components_utils_2.getClosest(card_body.querySelector("input[name$=mrn_number]"), ".form-group").classList.remove("d-none");
            if (_this.cargo_kind.value == '')
                card_body.classList.add('d-none');
            else
                card_body.classList.remove('d-none');
            if (_this.direction.value == '10') {
                if (ev.target.name.endsWith("out_cargo")) {
                    card_body.classList.add('d-none');
                }
            }
            else if (_this.direction.value == '20') {
                if (ev.target.name.endsWith("in_cargo")) {
                    card_body.classList.add('d-none');
                }
            }
            if (_this.cargo_kind.value == '10')
                containers_container.classList.remove('d-none');
            else
                containers_container.classList.add('d-none');
            if (_this.cargo_kind.value == '30') {
                description_container.classList.remove('d-none');
                mrn_number_container.classList.remove('d-none');
                components_utils_2.getClosest(card_body.querySelector("input[name$=mrn_number]"), ".form-group").classList.add("d-none");
            }
            else {
                description_container.classList.add('d-none');
                mrn_number_container.classList.remove('d-none');
            }
            if (_this.cargo_kind.value == '90') {
                description_container.classList.remove('d-none');
            }
            if (ev.target.value == "1") {
                if (_this.cargo_kind.value != '10')
                    card_body.querySelector('.containers_container').classList.add("d-none");
                else {
                    var is_full = card_body.querySelectorAll('select[name$=_is_full]');
                    is_full.forEach(function (iif) {
                        var group = components_utils_2.getClosest(iif, '.input-group');
                        if (group.querySelector("input").value == "")
                            choices_is_full[iif.name].setChoiceByValue("1");
                    });
                }
                mrn_number_container.classList.add("d-none");
                description_container.classList.add("d-none");
                if (_this.cargo_kind.value == '10') {
                    mrn_number_container.classList.remove("d-none");
                    components_utils_2.getClosest(card_body.querySelector("input[name$=mrn_number]"), ".form-group").classList.add("d-none");
                }
            }
            if (_this.in_cargo.value == "1" && _this.out_cargo.value == "1") {
                _this.description.classList.remove('d-none');
            }
            else {
                _this.description.classList.add('d-none');
            }
        };
        if (this.in_cargo) {
            this.in_cargo.onchange = cargo_onchange_fn;
        }
        if (this.out_cargo) {
            this.out_cargo.onchange = cargo_onchange_fn;
        }
        if (this.cargo_kind) {
            this.cargo_kind_field = new Choices(this.cargo_kind, { searchEnabled: false, searchChoices: false, shouldSort: false });
            this.cargo_kind.onchange = function (ev) {
                if (_this.cargo_kind.value == '30') {
                    _this.out_cargo.value = "1";
                }
                _this.in_cargo.dispatchEvent(new Event("change"));
                _this.out_cargo.dispatchEvent(new Event("change"));
            };
            this.cargo_kind.dispatchEvent(new Event("change"));
        }
        if (this.direction) {
            this.direction_field = new Choices(this.direction, { searchEnabled: false, searchChoices: false, shouldSort: false });
            this.direction.onchange = function (ev) {
                _this.in_cargo.dispatchEvent(new Event("change"));
                _this.out_cargo.dispatchEvent(new Event("change"));
            };
        }
        if (DISPLAY_E_INFO) {
            var div = document.createElement('div');
            this.row.querySelector("div.card-footer").appendChild(div);
        }
        if (this.is_e_permit) {
            if (this.email && this.phone) {
                this.is_e_permit.onchange = function (ev) {
                    var email_container = components_utils_2.getClosest(_this.email, '.col');
                    var phone_container = components_utils_2.getClosest(_this.phone, '.col');
                    if (_this.is_e_permit.checked) {
                        phone_container.classList.remove('d-none');
                        email_container.classList.remove('d-none');
                    }
                    else {
                        phone_container.classList.add('d-none');
                        email_container.classList.add('d-none');
                    }
                };
            }
            this.is_e_permit.dispatchEvent(new Event("change"));
        }
        if (this.email && this.email.getAttribute("data-autocomplete"))
            this.autocomplete(this.email);
        if (this.phone && this.phone.getAttribute("data-autocomplete"))
            this.autocomplete(this.phone);
    };
    return Submission;
}());
exports.Submission = Submission;
var submissionForm_update = document.getElementById("submissionForm_update");
if (submissionForm_update) {
    var submission_row = new Submission(submissionForm_update);
    submission_row.set_form_fields();
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
var submission_form = document.getElementById("submissionForm");
if (submission_form) {
    var territory_dd_1 = submission_form.querySelector("#id_territory");
    var responsible_dd = submission_form.querySelector("#id_responsible");
    var company_dd = submission_form.querySelector("#id_company");
    var delete_btn = submission_form.querySelector("input[name=delete]");
    var draft_save = submission_form.querySelector("input[name=submit_draft]");
    var draft_check = submission_form.querySelector("input[name=submit_check]");
    var save_button = submission_form.querySelector("input[name=submit]");
    var kind_1 = submission_form.querySelector("input[name=kind]");
    var company_dd_field_1 = undefined;
    var territory_dd_field_1 = undefined;
    if (responsible_dd && responsible_dd.type != 'hidden') {
        new Choices(responsible_dd, { searchEnabled: true, searchChoices: true, searchFields: ['label'] });
    }
    if (territory_dd_1) {
        territory_dd_field_1 = new Choices(territory_dd_1, { searchEnabled: false, searchChoices: false });
    }
    if (company_dd) {
        company_dd_field_1 = new Choices(company_dd, { searchEnabled: true, searchChoices: true });
    }
    if (territory_dd_1 && company_dd) {
        company_dd.onchange = function (ev) {
            territory_dd_field_1.setChoices(TERRITORY_COMPANY[company_dd_field_1.getValue(true)].territories, 'value', 'label', true);
            if (territory_dd_field_1.getValue(true)) {
                var should_update = true;
                for (var _a = 0, _b = TERRITORY_COMPANY[company_dd_field_1.getValue(true)].territories; _a < _b.length; _a++) {
                    var d = _b[_a];
                    if (d.value.toString() == territory_dd_field_1.getValue(true))
                        should_update = false;
                }
                if (should_update) {
                    territory_dd_field_1.setChoiceByValue(territory_dd_field_1._currentState.choices[0].value);
                }
            }
            else {
                if (territory_dd_1.value == "" && territory_dd_field_1._currentState.choices.length)
                    territory_dd_field_1.setChoiceByValue(territory_dd_field_1._currentState.choices[0].value);
            }
        };
        if (company_dd_field_1.getValue(true)) {
            company_dd.dispatchEvent(new Event("change"));
        }
    }
    if (territory_dd_1 && !isEmpty(TERRITORY_AUTOMATIC)) {
        territory_dd_1.onchange = function (ev) {
            var all_footers = document.querySelectorAll(".card-footer");
            for (var i_3 = 0; i_3 < all_footers.length; i_3++) {
                if (TERRITORY_AUTOMATIC[territory_dd_1.selectedOptions[0].value]) {
                    all_footers[i_3].style.display = "block";
                }
                else {
                    all_footers[i_3].style.display = "none";
                }
            }
        };
        territory_dd_1.dispatchEvent(new Event("change"));
    }
    if (delete_btn)
        delete_btn.onclick = function (ev) {
            kind_1.value = 'delete';
        };
    if (draft_save)
        draft_save.onclick = function (ev) {
            kind_1.value = 'draft';
        };
    save_button.onclick = function (ev) {
        kind_1.value = '';
    };
    if (draft_check)
        draft_check.onclick = function (ev) {
            kind_1.value = 'check';
        };
    var all_dynamic_forms = submission_form.querySelectorAll(".item");
    for (var i_4 = 0; i_4 < all_dynamic_forms.length; i_4++) {
        var element_1 = all_dynamic_forms[i_4];
        if (element_1.classList.contains('template'))
            continue;
        var submission_row = new Submission(element_1);
        submission_row.set_form_fields();
    }
    submission_form.onsubmit = function (ev) {
        var buttons = submission_form.querySelectorAll('input[type=submit]');
        for (var i = 0; i < buttons.length; i++) {
            var button_1 = buttons[i];
            button_1.disabled = true;
        }
    };
}
if (document.body.querySelector('.approve-reject')) {
    var button_list = document.body.querySelectorAll('.approve-reject button');
    for (var _i = 0; _i < button_list.length; _i++) {
        var element = button_list[_i];
        element.onclick = function (ev) {
            var default_classes = "btn btn-outline-primary";
            var button = ev.target;
            var input = button.parentElement.nextElementSibling;
            var type = button.dataset['type'];
            if (type == 'btn-success') {
                var other_button = button.nextElementSibling;
            }
            else {
                var other_button = button.previousElementSibling;
            }
            if (button.classList.contains(type)) {
                button.className = default_classes;
                input.value = "";
            }
            else {
                other_button.className = default_classes;
                button.className = "btn " + type;
                input.value = input.dataset['id'] + ":" + type;
            }
            var form = document.getElementById('li_companies_application_nr_' + input.dataset['company_id']);
            form.style.display = "block";
        };
    }
}
if (document.body.querySelector('.submission-modal')) {
    var button_list_1 = document.body.querySelectorAll('.submission-modal');
    submission_modal(button_list_1);
}
if (document.body.querySelector('.cancel-submission')) {
    var button_list_2 = document.body.querySelectorAll('.cancel-submission');
    for (var _i = 0; _i < button_list_2.length; _i++) {
        var button = button_list_2[_i];
        button.onclick = function (ev) {
            ev.preventDefault();
            var clicked_button = ev.target;
            var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
            alertify.confirm(gettext("Are you sure?"), function () {
                var data = new FormData();
                data.append('csrfmiddlewaretoken', csrf.value);
                data.append('pk', clicked_button.getAttribute('data-id'));
                data.append('task', 'cancel_item');
                fetch(window.location, {
                    credentials: 'same-origin',
                    method: 'POST',
                    body: data
                }).then(function () {
                    var closest_tr = components_utils_2.getClosest(clicked_button, "tr");
                    if (closest_tr)
                        closest_tr.remove();
                }).catch(function (ex) {
                    alertify.error("Error occurred. Try again later.");
                    if (window.console)
                        console.log('failed cancelling', ex);
                });
            }, function () {
            }).setHeader('Lūdzu apstipriniet, ka vēlaties atcelt caurlaidi');
        };
    }
}
if (document.body.querySelector('.visibility-to-public-submission')) {
    var button_list_3 = document.body.querySelectorAll('.visibility-to-public-submission');
    for (var _i = 0; _i < button_list_3.length; _i++) {
        var button = button_list_3[_i];
        button.onclick = function (ev) {
            ev.preventDefault();
            var clicked_button = ev.target;
            var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
            var data = new FormData();
            data.append('csrfmiddlewaretoken', csrf.value);
            data.append('pk', clicked_button.getAttribute('data-id'));
            data.append('task', 'visibility_public');
            fetch(window.location, {
                credentials: 'same-origin',
                method: 'POST',
                body: data
            }).then(function () {
                clicked_button.classList.add("d-none");
                clicked_button.parentElement.querySelector(".visibility-to-me-submission").classList.remove("d-none");
                alertify.success("Veiksmīgi nomainīta caurlaides redzamība. Tagad caurlaidi redz visi darbinieki uzņēmuma iekšienē.");
            }).catch(function (ex) {
                alertify.error("Error occurred. Try again later.");
                if (window.console)
                    console.log('failed changing visibility', ex);
            });
        };
    }
}
if (document.body.querySelector('.visibility-to-me-submission')) {
    var button_list_4 = document.body.querySelectorAll('.visibility-to-me-submission');
    for (var _i = 0; _i < button_list_4.length; _i++) {
        var button = button_list_4[_i];
        button.onclick = function (ev) {
            ev.preventDefault();
            var clicked_button = ev.target;
            var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
            var data = new FormData();
            data.append('csrfmiddlewaretoken', csrf.value);
            data.append('pk', clicked_button.getAttribute('data-id'));
            data.append('task', 'visibility_me');
            fetch(window.location, {
                credentials: 'same-origin',
                method: 'POST',
                body: data
            }).then(function () {
                clicked_button.classList.add("d-none");
                clicked_button.parentElement.querySelector(".visibility-to-public-submission").classList.remove("d-none");
                alertify.success("Veiksmīgi nomainīta caurlaides redzamība. Tagad caurlaidi redz tikai iesaistītie darbinieki.");
            }).catch(function (ex) {
                alertify.error("Error occurred. Try again later.");
                if (window.console)
                    console.log('failed changing visibility', ex);
            });
        };
    }
}
if (document.body.querySelector(".submission-delete")) {
    var button_list_5 = document.body.querySelectorAll('.submission-delete');
    for (var _i = 0; _i < button_list_5.length; _i++) {
        var button = button_list_5[_i];
        button.onclick = function (ev) {
            var clicked_button = ev.target;
            var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
            alertify.confirm(gettext("Are you sure?"), function () {
                var data = new FormData();
                data.append('csrfmiddlewaretoken', csrf.value);
                fetch(clicked_button.dataset['url'], {
                    credentials: 'same-origin',
                    method: 'POST',
                    body: data
                }).then(function (response) {
                    var closest_tr = components_utils_2.getClosest(clicked_button, "tr");
                    if (clicked_button.dataset['redirect'])
                        window.location.href = clicked_button.dataset['redirect'];
                    if (closest_tr)
                        closest_tr.remove();
                }).catch(function (ex) {
                    alertify.error("Error occurred. Try again later.");
                    if (window.console)
                        console.log('failed deleting', ex);
                });
            }, function () {
            });
        };
    }
}
if (document.body.querySelector('.user-form')) {
    var company_choices = document.getElementById("id_parent_company");
    var primary_company_choices = document.getElementById("id_company");
    var territories_choices = document.getElementById("id_territories");
    if (company_choices) {
        new Choices(company_choices);
    }
    if (primary_company_choices) {
        new Choices(primary_company_choices);
    }
    if (territories_choices) {
        new Choices(territories_choices, {
            search: false,
            maxItemCount: 30,
        });
    }
}
if (document.body.querySelector('.submission-territory-form')) {
    if (document.body.querySelector('.territory_ws_view')) {
        var territory_view = new TerritoryView(true);
    }
    else {
        var territory_view = new TerritoryView();
    }
}
if (document.body.querySelector('.submission-user-table')) {
    var all_buttons = document.querySelectorAll(".submission-user-table button.modal_box");
    for (var i = 0; i < all_buttons.length; i++) {
        var current_button = all_buttons[i];
        current_button.onclick = function (ev) {
            var button = ev.target;
            console.log(button);
            var modal = document.querySelector("#change-permission");
            modal.querySelector("#permission-due_date").value = button.getAttribute('data-due_date');
            modal.querySelector("#permission-form_title").innerHTML = button.getAttribute('data-form_title');
            modal.querySelector("#permission-username").innerHTML = button.getAttribute('data-username');
            modal.querySelector("#permission-form_id").value = button.getAttribute('data-form_id');
            modal.querySelector("#permission-user_id").value = button.getAttribute('data-user_id');
            var l = components_utils_2.bsn_function("Modal", "#change-permission");
            l.show();
        };
    }
    var modal_1 = document.querySelector("#change-permission");
    modal_1.querySelector(".btn-danger").onclick = function (ev) {
        modal_1.querySelector("#permission-due_date").value = "";
    };
    var form = modal_1.querySelector("form");
    form.onsubmit = function (ev) {
        ev.preventDefault();
        var form_element = ev.target;
        var data = new FormData(form_element);
        fetch(form_element.getAttribute("action"), {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            var button = document.querySelector("button.modal_box[data-form_id='" + response.form_id + "'][data-user_id='" + response.user_id + "']");
            button.innerHTML = response.icon;
            button.setAttribute("data-due_date", response.due_date || "");
            button.setAttribute("data-original-title", response.title || "Nav tiesību");
            var l = components_utils_2.bsn_function("Modal", "#change-permission");
            l.hide();
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('failed deleting', ex);
        });
    };
}
if (document.body.querySelector(".oiav-form")) {
    var id_user = document.getElementById("id_user");
    var id_company = document.getElementById("id_company");
    var id_territory = document.getElementById("id_territory");
    new Choices(id_user);
    new Choices(id_company);
    new Choices(id_territory);
}
if (document.body.querySelector(".oiav-ipz-change")) {
    var fields = document.body.querySelectorAll('.ipzchoices');
    fields.forEach(function (field) {
        new Choices(field, { searchEnabled: false, searchChoices: false });
    });
}
if (document.getElementById('id_pin-check-view')) {
    var camQrResult_1 = document.getElementById('cam-qr-result');
    var v4_1 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    var setResult_1 = function (label, result) {
        if (result.data.match(v4_1)) {
            show_epermit_1(result.data);
        }
        console.log(result.data);
        label.textContent = result.data;
    };
    var scanner_1 = new qr_scanner_1.default(document.getElementById('qr-video'), function (result) { return setResult_1(camQrResult_1, result); }, {
        onDecodeError: function (error) {
            camQrResult_1.textContent = error.toString();
            camQrResult_1.style.color = 'inherit';
        },
        maxScansPerSecond: 0.5,
        highlightScanRegion: true,
        highlightCodeOutline: true,
    });
    scanner_1.start();
    document.getElementById('start-button').addEventListener('click', function () {
        scanner_1.start();
    });
    document.getElementById('stop-button').addEventListener('click', function () {
        scanner_1.stop();
    });
    var show_epermit_1 = function (code) {
        var csrf_input = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf_input.value);
        data.append('action', "check");
        data.append('pin_code', code);
        fetch(window.location.href, {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response.status && response.hasOwnProperty('data')) {
                var modal_div = document.querySelector('#id_item_detail_modal');
                var modal_data_description_list = modal_div.querySelector('#id_item_detail_modal_data_list');
                while (modal_data_description_list.firstChild)
                    modal_data_description_list.removeChild(modal_data_description_list.lastChild);
                for (var i_5 = 0; i_5 < response.data.length; i_5++) {
                    var row = response.data[i_5];
                    if (row.hasOwnProperty('title') && row.hasOwnProperty('value')) {
                        var dt_title = document.createElement('dt');
                        dt_title.classList.add('col-4');
                        dt_title.classList.add('text-right');
                        dt_title.textContent = row.title;
                        var dd_value = document.createElement('dd');
                        dd_value.classList.add('col-8');
                        dd_value.textContent = row.value;
                        modal_data_description_list.appendChild(dt_title);
                        modal_data_description_list.appendChild(dd_value);
                    }
                }
                if (response.hasOwnProperty('link')) {
                    var item_a = modal_div.querySelector('#id_open_external');
                    item_a.href = response.link;
                }
                var show_exit_btn = modal_div.querySelector("#id_show_exit");
                var already_exited = modal_div.querySelector("#id_already_exited");
                var already_marked = modal_div.querySelector("#id_already_marked");
                if (response.show_exit) {
                    show_exit_btn.classList.remove("d-none");
                    show_exit_btn.setAttribute("data-pin_code", response.additional_data.pin_code);
                    already_exited.classList.add("d-none");
                    already_marked.classList.add("d-none");
                }
                else {
                    show_exit_btn.classList.add("d-none");
                    if (response.additional_data.exited_territory) {
                        already_marked.classList.add("d-none");
                        already_exited.classList.remove("d-none");
                    }
                    else if (response.additional_data.can_exit_set) {
                        already_marked.classList.remove("d-none");
                        already_exited.classList.add("d-none");
                    }
                }
                var modal = components_utils_2.bsn_function("Modal", "#id_item_detail_modal");
                modal.show();
            }
            else {
                alertify.error(response.message);
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('PIN Check failed!', ex);
        });
    };
    var submit_button = document.querySelector('#id_pin_submit');
    submit_button.onclick = function (ev) {
        var pin_input = document.querySelector('#id_item_pin_code');
        show_epermit_1(pin_input.value);
    };
    var allow_exit_1 = document.querySelector('#id_show_exit');
    allow_exit_1.onclick = function (ev) {
        var csrf_input = document.querySelector("input[name='csrfmiddlewaretoken']");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf_input.value);
        data.append('action', "allow_exit");
        data.append('pin_code', allow_exit_1.getAttribute("data-pin_code"));
        fetch(window.location.href, {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response.status) {
                var modal = components_utils_2.bsn_function("Modal", "#id_item_detail_modal");
                modal.hide();
                alertify.success("Veiksmīgi atzīmēts, ka var pamest teritoriju");
            }
            else {
                alertify.error(response.message);
            }
        }).catch(function (ex) {
            alertify.error("Error occurred. Try again later.");
            if (window.console)
                console.log('PIN Check failed!', ex);
        });
    };
}
