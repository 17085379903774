"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var _flatpickr = require("flatpickr");
var flatpickr = _flatpickr;
var forms_1 = require("./forms");
var FilterUI = (function () {
    function FilterUI() {
    }
    FilterUI.daterangefilter = function (item) {
        var range_dt = item.parentElement.parentElement.nextElementSibling;
        if (item.value === "choose") {
            range_dt.style.display = 'block';
        }
        else {
            range_dt.style.display = 'none';
            var dt_1 = range_dt.querySelectorAll('input');
            for (var _ii = 0; _ii < dt_1.length; _ii++) {
                var item2 = dt_1[_ii];
                item2.value = "";
            }
        }
        var dt = range_dt.querySelectorAll('input');
        for (var _ii = 0; _ii < dt.length; _ii++) {
            var element = dt[_ii];
            flatpickr(element, __assign({}, forms_1.dateOptions));
        }
    };
    FilterUI.prototype.addHandlers = function () {
        var matches = document.body.querySelectorAll('.daterangefilter');
        for (var _i = 0; _i < matches.length; _i++) {
            var item = matches[_i];
            FilterUI.daterangefilter(item);
            item.onchange = function (ev) {
                FilterUI.daterangefilter(ev.srcElement);
            };
        }
    };
    return FilterUI;
}());
var filter_ui = new FilterUI();
filter_ui.addHandlers();
