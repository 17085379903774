"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Choices = require("choices.js");
if (document.querySelector(".stop-shift-btn")) {
    var button_list = document.querySelectorAll('.stop-shift-btn');
    var _loop_1 = function (i) {
        var btn = button_list[i];
        btn.onclick = function (ev) {
            var modal = document.querySelector("#endShiftModal");
            var tnumInput = modal.querySelector("input#endShiftModalEmployeeTnum");
            var employeeNameSpan = modal.querySelector("span#endShiftModalEmployeeNameSpan");
            tnumInput.value = btn.dataset.employeeTnum;
            employeeNameSpan.textContent = btn.dataset.employeeFullname;
        };
    };
    for (var i = 0; i < button_list.length; i++) {
        _loop_1(i);
    }
}
if (document.getElementById("id_loadMoreReports")) {
    var showMoreButton = document.getElementById("id_loadMoreReports");
    showMoreButton.onclick = function (ev) {
        var btn = ev.target;
        var pageNr = parseInt(btn.dataset.page) + 1;
        fetch(window.location.href + ("?next_page=" + pageNr), { credentials: 'same-origin' }).then(function (response) { return response.json(); }).then(function (response) {
            if (response.status) {
                var wrapper = document.getElementById("id_lastReportWrapper");
                for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                    var entry = _a[_i];
                    var rowDiv = document.createElement("div");
                    rowDiv.classList.add("row");
                    var timeCol = document.createElement("div");
                    timeCol.className = "col text-bold";
                    timeCol.textContent = entry.created;
                    rowDiv.appendChild(timeCol);
                    var employeeCol = document.createElement("div");
                    employeeCol.className = "col";
                    employeeCol.textContent = entry.employee;
                    rowDiv.appendChild(employeeCol);
                    var messageCol = document.createElement("div");
                    messageCol.className = "col";
                    messageCol.textContent = entry.message;
                    rowDiv.appendChild(messageCol);
                    wrapper.appendChild(rowDiv);
                }
                btn.dataset.page = pageNr.toString();
            }
        }).catch(function (ex) {
            alert("Error occurred. Try again later.");
            if (window.console)
                console.log('failed setting in/out', ex);
        });
    };
}
if (document.getElementById("addShiftEmployeeTnum")) {
    document.getElementById("addShiftEmployeeTnum").onkeyup = function (ev) {
        var el = ev.target;
        var csrf = document.querySelector("input[name='csrfmiddlewaretoken']");
        var info_div = document.getElementById("addShiftEmployeeTnum_info");
        var data = new FormData();
        data.append('csrfmiddlewaretoken', csrf.value);
        data.append('action', 'get_name');
        data.append('tnum', el.value);
        fetch(window.location, {
            credentials: 'same-origin',
            method: 'POST',
            body: data
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            console.log(response);
            if (response.result == 'ok') {
                info_div.innerHTML = 'Darbinieks: <b>' + response.name + '</b>';
            }
            else {
                info_div.innerHTML = 'Darbinieks nav atrasts';
            }
        }).catch(function (ex) {
            alert("Error occurred. Try again later.");
        });
    };
}
if (document.querySelector(".shift-filter")) {
    var selectpickers = document.body.querySelectorAll('.selectpicker');
    selectpickers.forEach(function (iif) {
        new Choices(iif);
    });
}
