"use strict";
var components_utils_1 = require("./components_utils");
var Formset = (function () {
    function Formset(formset, options) {
        this.insertDeleteLink = function (row) {
            var _this = this;
            var delete_button;
            if (this.options.deleteButtonSelector) {
                delete_button = row.querySelector(this.options.deleteButtonSelector);
            }
            else {
                delete_button = document.createElement("button");
                delete_button.setAttribute("class", this.options.deleteCssClass);
                delete_button.setAttribute("type", "button");
                delete_button.innerHTML = this.options.deleteText;
            }
            if (!this.showDeleteLinks())
                delete_button.style.display = "none";
            delete_button.onclick = function (ev) {
                var forms;
                var button = ev.target;
                var row = components_utils_1.getClosest(button, '.' + _this.options.formCssClass);
                var del = row.querySelector('input[type=hidden][id $= "-DELETE"]');
                var id = row.querySelector('input[type=hidden][id $= "_ptr"],input[type=hidden][id $= "-id"]');
                if (del && id.value) {
                    del.value = "on";
                    row.style.display = "none";
                    forms = _this.getAllForms();
                }
                else {
                    row.parentNode.removeChild(row);
                    forms = _this.getAllForms();
                    _this.admin.totalForms.value = forms.length.toString();
                }
                _this.forEach(forms, function (form_index, form) {
                    if (!(del && id.value)) {
                        var child_elements = form.querySelectorAll(_this.options.childElementSelector.join());
                        _this.forEach(child_elements, function (index, element) {
                            _this.updateElementIndex(element, _this.options.prefix, form_index);
                        });
                    }
                });
                if (!_this.showDeleteLinks()) {
                    _this.showDelete(false);
                }
                if (_this.showAddButton()) {
                    _this.addButton.style.display = "initial";
                }
                if (_this.options.removed)
                    _this.options.removed(row);
            };
        };
        this.formset = formset;
        this.options = options;
        this.container = this.options.container;
        if (!this.options.prefix)
            this.options.prefix = "form";
        if (this.options.addText === undefined)
            this.options.addText = "Add";
        if (this.options.deleteText === undefined)
            this.options.deleteText = "Remove";
        if (!this.options.addCssClass)
            this.options.addCssClass = "add-row";
        if (!this.options.deleteCssClass)
            this.options.deleteCssClass = "delete-row";
        if (!this.options.formCssClass)
            this.options.formCssClass = "dynamic-form";
        if (!this.options.upCssClass)
            this.options.upCssClass = "icon-arrow-up";
        if (!this.options.downCssClass)
            this.options.downCssClass = "icon-arrow-down";
        if (!this.options.childElementSelector)
            this.options.childElementSelector = ['input', 'select', 'textarea', 'label', 'div', 'img'];
        this.admin = {
            totalForms: document.getElementById('id_' + this.options.prefix + '-TOTAL_FORMS'),
            initialForms: document.getElementById('id_' + this.options.prefix + '-INITIAL_FORMS'),
            minForms: document.getElementById('id_' + this.options.prefix + '-MIN_NUM_FORMS'),
            maxForms: document.getElementById('id_' + this.options.prefix + '-MAX_NUM_FORMS')
        };
        this.can_add_new = (this.formset.dataset['canAddNew'] == 'True');
        this.can_delete = (this.formset.dataset['canDelete'] == 'True');
        this.delete_prepend = (this.formset.dataset['deletePrepend'] == 'True');
    }
    Formset.prototype.forEach = function (array, callback) {
        for (var i = 0; i < array.length; i++) {
            callback.call(this, i, array[i]);
        }
    };
    ;
    Formset.prototype.isHidden = function (form) {
        return !!(form.offsetWidth || form.offsetHeight || form.getClientRects().length);
    };
    ;
    Formset.prototype.updateElementIndex = function (elem, prefix, ndx) {
        var idRegex = new RegExp(prefix + '-(\\d+|__prefix__)-'), replacement = prefix + '-' + ndx + '-';
        if (elem.getAttribute("for"))
            elem.setAttribute("for", elem.getAttribute("for").replace(idRegex, replacement));
        if (elem.getAttribute('id'))
            elem.setAttribute('id', elem.getAttribute('id').replace(idRegex, replacement));
        if (elem.getAttribute('name'))
            elem.setAttribute('name', elem.getAttribute('name').replace(idRegex, replacement));
    };
    ;
    Formset.prototype.hasChildElements = function (row) {
        return row.querySelectorAll(this.options.childElementSelector.join()).length > 0;
    };
    ;
    Formset.prototype.showAddButton = function () {
        return this.can_add_new && (this.admin.maxForms.value == '' || (parseInt(this.admin.maxForms.value) - parseInt(this.admin.totalForms.value) > 0));
    };
    ;
    Formset.prototype.showDeleteLinks = function () {
        return this.can_delete && (this.admin.minForms.value == '' || (parseInt(this.admin.totalForms.value) - parseInt(this.admin.minForms.value) > 0));
    };
    ;
    Formset.prototype.getAddSelector = function () {
        return "." + this.options.addCssClass.trim().replace(/\s+/g, '.');
    };
    Formset.prototype.getAllForms = function () {
        return this.container.querySelectorAll('.' + this.options.formCssClass + ':not(.template):not(.hidden)');
    };
    Formset.prototype.getAllFormsInitial = function () {
        return this.container.querySelectorAll('.item:not(.template):not(.hidden)');
    };
    Formset.prototype.showDelete = function (show) {
        var all_delete_buttons;
        if (this.options.deleteButtonSelector)
            all_delete_buttons = this.container.querySelectorAll(this.options.deleteButtonSelector);
        else
            all_delete_buttons = this.container.querySelectorAll("." + this.options.deleteCssClass);
        this.forEach(all_delete_buttons, function (index, delete_button) {
            if (show)
                delete_button.style.display = "initial";
            else
                delete_button.style.display = "none";
        });
    };
    Formset.prototype.replaceDeleteLink = function (row) {
        var del = row.querySelector('input[type=checkbox][id $= "-DELETE"]');
        if (del) {
            var delete_input = document.createElement("input");
            delete_input.setAttribute("type", "hidden");
            delete_input.setAttribute("name", del.getAttribute("name"));
            delete_input.setAttribute("id", del.id);
            if (del.checked) {
                delete_input.value = "on";
                row.style.display = "none";
            }
            row.insertAdjacentElement('beforeend', delete_input);
            var del_label = row.querySelector('label[for="' + del.id + '"]');
            del_label.style.display = "none";
            del.parentNode.removeChild(del);
        }
    };
    ;
    Formset.prototype.process = function () {
        var _this = this;
        var forms = this.getAllFormsInitial();
        this.forEach(forms, function (index, form) {
            _this.insertDeleteLink(form);
            _this.replaceDeleteLink(form);
            if (_this.hasChildElements(form)) {
                form.classList.add(_this.options.formCssClass);
                if (!_this.isHidden(form) && _this.can_delete)
                    _this.insertDeleteLink(form);
            }
        });
        if (this.options.formTemplateSelector) {
            this.template = this.formset.querySelector(this.options.formTemplateSelector);
            this.template.parentNode.removeChild(this.template);
        }
        else {
            var forms_1 = this.getAllForms();
            this.template = forms_1[forms_1.length - 1].cloneNode(true);
            this.template.removeAttribute("id");
            var delete_input = this.template.querySelector('input[id $= "-DELETE"]');
            if (delete_input)
                delete_input.parentNode.removeChild(delete_input);
            var child_elements = this.template.querySelectorAll(this.options.childElementSelector.join());
            this.forEach(child_elements, function (index, element) {
                if (element.type == 'checkbox' || element.type == 'radio')
                    element.checked = false;
                else
                    element.value = "";
            });
        }
        this.template.classList.remove("template");
        if (this.options.addCssSelector) {
            this.addButton = this.formset.querySelector(this.options.addCssSelector);
        }
        else {
            this.addButton = document.createElement("button");
            this.addButton.setAttribute("class", this.options.addCssClass);
            this.addButton.setAttribute("type", "button");
            this.addButton.innerHTML = this.options.addText;
            var create_btn_wrapper = document.createElement("div");
            create_btn_wrapper.setAttribute("class", "button-add");
            create_btn_wrapper.appendChild(this.addButton);
            this.formset.insertAdjacentElement('beforeend', create_btn_wrapper);
        }
        if (!this.showAddButton())
            this.addButton.style.display = "none";
        this.addButton.onclick = function () {
            if (parseInt(_this.admin.totalForms.value) >= parseInt(_this.admin.maxForms.value)) {
                alert('Reached maximum row count.');
                return false;
            }
            var form = _this.template.cloneNode(true);
            _this.replaceDeleteLink(form);
            _this.insertDeleteLink(form);
            form.classList.add(_this.options.formCssClass);
            var child_elements = form.querySelectorAll(_this.options.childElementSelector.join());
            _this.forEach(child_elements, function (index, element) {
                _this.updateElementIndex(element, _this.options.prefix, parseInt(_this.admin.totalForms.value));
            });
            form.style.display = "block";
            _this.container.insertAdjacentElement('beforeend', form);
            _this.admin.totalForms.value = (parseInt(_this.admin.totalForms.value) + 1).toString();
            if (!_this.showAddButton())
                _this.addButton.style.display = "none";
            if (_this.options.added)
                _this.options.added(form);
            if (_this.showDeleteLinks()) {
                _this.showDelete(true);
            }
        };
    };
    ;
    return Formset;
}());
HTMLElement.prototype.formset = function (options) {
    var formset = new Formset(this, options);
    formset.process();
};
