// Preloader
// -----------------------------------


document.body.onload = function(){
    var preloader = document.getElementById('preloader');
    if (preloader) {
        if (!preloader.classList.contains('done')) {
            preloader.classList.add('done');
        }
    }
};
